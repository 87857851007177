<template>
    <div class="w-[80vw] md:w-[38vw]">
        <div class="text-2xl font-medium text-gray-800">
            Notifications
        </div>
        <div class="mt-4">
            <div class="flex justify-start overflow-x-auto text-[12.5px] tracking-wide  whitespace-nowrap text-gray-700 items-start gap-2 w-full relative">
                <div v-for="(tab, i) in tabnav" :key="i" :class="[
                'h-full px-[14px] cursor-pointer tab-label text-center hover:bg-gray-200 py-[5px] rounded mb-[3px]',
                tab.value === selected_tab ? 'tab-label-active' : '', // Atur garis bawah
                ]" @click="updatetab(tab.value)">
                {{ tab.label }}
                </div>
            </div>
            <div class="bg-gray-100 w-full h-[3px] top-[-2px] relative"></div>
        </div>
        <div class="py-3 flex justify-between items-center gap-2">
            <v-text-field placeholder="Search all notifiactions" append="magnify" clickAppend></v-text-field>
            <button class="text-gray-700 whitespace-nowrap hover:bg-gray-200 hover:bg-opacity-70 active:scale-95 p-2 rounded duration-300 ease text-[13px] tracking-wide flex justify-between items-center gap-1">
                <div>
                    <mdicon name="account-circle-outline"></mdicon>
                </div>
                <div>
                    Filter by person
                </div>
                <div>
                    <mdicon name="chevron-down"></mdicon>
                </div>
            </button>
        </div>
        <div class="h-[62vh] overflow-y-auto">
            <slotVue v-if="selected_tab == 1">
                <allNotification :data="filteredData"  @close="$emit('close')"></allNotification>
            </slotVue>
            <slotVue v-if="selected_tab == 2">
                <punreadNotification :data="filteredData" @close="$emit('close')"></punreadNotification>
            </slotVue>
            <slotVue v-if="selected_tab == 3">
               <mentionNotification :data="filteredData" @close="$emit('close')"></mentionNotification>
            </slotVue>
            <slotVue v-if="selected_tab == 4">
                <assignNotification :data="filteredData" @close="$emit('close')"></assignNotification>
            </slotVue>
        </div>
    </div>
</template>

<script>
import mentionNotification from './mention_notification.vue'
import assignNotification from './assign_notification.vue'
import punreadNotification from './unread_notification.vue'
import allNotification from './all_notification.vue'
import slotVue from './slot.vue'
import store from '@/store';
export default {
    name: 'PadmaErpNotification',

    data() {
        return {
            selected_tab : 1,
            tabnav : [
                {
                    label : 'All',
                    value : 1
                },
                {
                    label : 'Unread',
                    value : 2
                },
                {
                    label : 'I Was mentioned',
                    value : 3
                },
                {
                    label : 'Assigned to me',
                    value : 4
                },
            ],

            data: []
        };
    },

    mounted() {

    },

    methods: {
        updatetab(val){
            this.selected_tab = val
        },

    },

    computed : {
        filteredData() {
            let employeeId = this.loggedInUser.employee_id
            let notif = store.state.notifications;
            return notif.filter(item => {
                if (item.mentions !== null) {
                    return item.assign === employeeId || item.mentions.some(mention => mention.employee_id === employeeId);
                } else {
                    return item.assign === employeeId;
                }
            });
        }
    },
    components : {
        slotVue,
        allNotification,
        punreadNotification,
        assignNotification,
        mentionNotification
    }
};
</script>

<style lang="scss" scoped>
/* width */
::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}


::-webkit-scrollbar-track {
    @apply bg-gray-300 bg-opacity-30;
// background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
 @apply bg-gray-300 bg-opacity-50;
// background: rgb(185, 185, 185); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300 bg-opacity-100;
// background: rgb(182, 182, 182); 
}

.tab-label {
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.tab-label::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  width: 0;
  height: 2.2px;
  z-index: 10;
  transition: 0.3s;
  transform: translateX(-50%);
  @apply bg-primary-500;
}

// .tab-label:hover::before,
.tab-label-active::before {
  width: 100%;
}
</style>