export default {
    state : {
        employees : [],
    },
    mutations : {        
        SET_EMPLOYEES(state, payload) {
            state.employees = payload;
        },
    },

    actions : {
        setEmployees({ commit }, payload) {
            commit('SET_EMPLOYEES', payload);
        },
    },

    getters : {

    }

}