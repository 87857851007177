<template>
    <div>
        <transition name="nested" duration="550"  @after-enter="addClickOutsideListener" @after-leave="removeClickOutsideListener">   
            <div class="absolute z-40 rounded-lg right-4 top-12" v-if="isOpen">
                <div class="inner bg-white w-[80vw] md:w-[25vw] rounded-lg shadow text-gray-600 ">
                    <div class="w-full border-b flex justify-start gap-3 items-center px-4 py-2">
                        <div>
                            <img :src="logo" alt="logo padma" class="w-10">
                        </div>
                        <div class="text-[14px]">
                            {{loggedInUser.employee.employee_name}}
                        </div>
                    </div>
                    <div class="w-full border-b min-h-[200px]">
                        <div class="w-full flex flex-col-reverse md:grid md:grid-cols-2 gap-4 px-2 py-4">
                            <div>
                                <div class="text-[14px] px-4 mb-3">
                                    Account
                                </div>
                                <ul>
                                    <li>
                                        <div class="flex justify-start items-center gap-1 hover:bg-gray-200 rounded px-4 py-[7px] duration-300 ease-in-out cursor-pointer" @click="$emit('profile')">
                                            <mdicon name="account-outline" size="18"></mdicon>
                                            <div class="text-[13px]">My Account</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex justify-start items-center gap-1 hover:bg-gray-200 rounded px-4 py-[7px] duration-300 ease-in-out cursor-pointer"  @click="$emit('payslip_password')">
                                            <mdicon name="file-document-alert-outline" size="18"></mdicon>
                                            <div class="text-[13px]">Payslip Password</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex justify-start items-center gap-1 hover:bg-gray-200 rounded px-4 py-[7px] duration-300 ease-in-out cursor-pointer"  @click="$emit('change_password')">
                                            <mdicon name="key-variant" size="18"></mdicon>
                                            <div class="text-[13px]">Change Password</div>
                                        </div>
                                    </li>  
                                    <li>
                                        <div class="flex justify-start items-center gap-1 hover:bg-gray-200 rounded px-4 py-[7px] duration-300 ease-in-out cursor-pointer"  @click="$emit('logout')">
                                            <mdicon name="logout-variant" size="18"></mdicon>
                                            <div class="text-[13px]">Logout</div>
                                        </div>
                                    </li>                    
                                </ul>
                            </div>
                            <div>
                                <div class="text-[14px] px-4 mb-3">
                                    Explore
                                </div>
                                <ul>
                                    <li>
                                        <div class="flex justify-start items-center gap-1 hover:bg-gray-200 rounded px-4 py-[7px] duration-300 ease-in-out cursor-pointer">
                                            <mdicon name="email-outline" size="18"></mdicon>
                                            <div class="text-[13px]">Message</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="flex justify-start items-center gap-1 hover:bg-gray-200 rounded px-4 py-[7px] duration-300 ease-in-out cursor-pointer" @click="$emit('notif')">
                                            <mdicon name="bell-outline" size="18"></mdicon>
                                            <div class="text-[13px]">Notification</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="w-full border-t h-10">          
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import logo from '@/assets/logo-kecil.svg'
export default {
    name: 'ErpUserinfo',

    props: {
        modelValue: Boolean, // Menggunakan modelValue sebagai nama properti untuk v-model
    },

    data() {
        return {
            logo: logo,
        };
    },

    methods: {
        closeInfo() {
            this.$emit('update:modelValue', false);
        },

        addClickOutsideListener() {
            // Tambahkan event listener ketika komponen muncul
            document.addEventListener('click', this.closeOnOutsideClick);
        },
        removeClickOutsideListener() {
            // Hapus event listener ketika komponen hilang
            document.removeEventListener('click', this.closeOnOutsideClick);
        },
        closeOnOutsideClick(event) {
            // Cek apakah klik dilakukan di luar .inner
            const innerElement = this.$el.querySelector('.inner');
            if (innerElement && !innerElement.contains(event.target)) {
                this.closeInfo();
            }
        },

    },

    computed : {
        isOpen: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    }
};
</script>

<style lang="scss" scoped>

.nested-enter-active .inner,
.nested-leave-active .inner { 
  transition: all 0.3s ease;
  transform-origin: top right;
}
/* delay enter of nested element */

.nested-enter-from .inner,
.nested-leave-to .inner {
  opacity: 0.01;
  scale: 0;
}

</style>