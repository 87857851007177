<template>
    <div class="w-full">
        <div class="text-lg w-full flex gap-2 text-gray-800 mb-5">
            <div>Document</div>
            <div class="flex justify-center items-center">
                <mdicon name="file-document-multiple" class="text-gray-700" size="20"></mdicon>
            </div>               
        </div>

        <div v-for="(doc,index) in data.documents" :key="index" class="mb-5">
            <div class="flex justify-between items-center gap-2 border border-gray-400 hover:bg-gray-100 duration-300 ease-in-out rounded px-4 py-4">
                <div class="flex justify-start items-center gap-6">
                    <mdicon name="credit-card-outline" size="28" class="text-gray-600"></mdicon>
                    <div class="text-[16px] flex flex-col gap-1 font-medium text-gray-700">
                        <div>
                            {{ doc.title }}
                        </div>
                        <div class="text-[14px] font-light">
                            {{ doc.description }}
                        </div>
                    </div>
                </div>
                <a :href="doc.document_path" target="_blank">
                    <v-btn type="primary">Download Document</v-btn>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmaErpDocument',
    props : ['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>