<template>
    <div class="inline-block">
        <button v-if="icon" :disabled="disabled">
            <mdicon :name="icon" :size="iconSize" class="cursor-pointer " :class="disabled ? 'text-gray-400' : 'text-gray-600 hover:text-gray-700'"></mdicon>
        </button>
        <button
            v-if="!icon"
            class="whitespace-nowrap rounded focus:outline-none flex justify-center items-center gap-[4px]"
            :class="[
                {
                    'py-[4px] px-[8px] text-[11px]': size === 'small',
                    'py-[7px] px-[11px] text-[13px]': size === 'normal',
                    'py-[7px] px-[11px] text-[15px]': size === 'large',
                    'opacity-50 cursor-not-allowed': disabled
                },
                {
                    'opacity-50 cursor-not-allowed': disabled,
                    'bg-primary-600 hover:bg-primary-700 active:bg-primary-500 text-white duration-300 ease-in-out drop-shadow shadow hover:shadow-md hover:drop-shadow-md': type === 'primary',
                    'bg-warning-300 hover:bg-warning-400 active:bg-warning-400 text-gray-900 duration-300 ease-in-out drop-shadow shadow hover:shadow-md hover:drop-shadow-md': type === 'warning',
                    'bg-danger-600  hover:bg-danger-700 active:bg-danger-500 text-white duration-300 ease-in-out drop-shadow shadow hover:shadow-md hover:drop-shadow-md': type === 'danger',
                    'bg-secondary-600 hover:bg-secondary-700 active:bg-secondary-500 text-white duration-300 ease-in-out drop-shadow shadow hover:shadow-md hover:drop-shadow-md': type === 'secondary',
                    'bg-transparent hover:bg-gray-200 text-gray-600 active:bg-gray-200 duration-200 ease-in-out': type === 'text',
                    'bg-transparent hover:text-gray-900 text-gray-600 duration-200 ease-in-out': type === 'plain',
                    'bg-white hover:bg-gray-100 text-gray-900 active:bg-gray-400 duration-300 ease-in-out drop-shadow shadow hover:shadow-md hover:drop-shadow-md': type === 'default',
                    'bg-transparent hover:bg-gray-200 text-gray-900 active:bg-gray-400 border border-gray-700 duration-300 ease-in-out': type === 'outlined',
                }
            ]"
            :disabled="disabled">
            
            <mdicon v-if="prepend" :name="prepend" :size="size === 'small' ? '17' : size === 'normal' ? '20' : '25'"></mdicon>
            <slot></slot>
            <mdicon v-if="append" :name="append" :size="size === 'small' ? '17' : size === 'normal' ? '20' : '25'"></mdicon>
        </button>
    </div>
</template>

<script>

    export default {
        name: 'PadmaErpVBtn',
        props: {
            prepend: {
                type: [String, Boolean],
                default: false
            },
            append: {
                type: [String, Boolean],
                default: false
            },
            elevated : {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: 'normal'
            },
            type :{
                type: String,
                default: 'default'
            },
            icon : {
                type: [String, Boolean],
                default: false
            }
        },

        data() {
            return {};
        },

        mounted() {},

        methods: {
        },
        computed: {
            iconSize() {
                switch (this.size) {
                    case 'small':
                    return 22
                    case 'normal':
                    return 26
                    case 'large':
                    return 30
                    default:
                    return 26
                }
            }
        }
        
    };
</script>

<style lang="scss" scoped="scoped">
button:focus {
  outline: none;
}

</style>