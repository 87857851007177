<template>
  <div class="relative w-full">
    <label class="block text-gray-700 text-sm font-medium mb-2" v-if="label">{{ label }} <span class="text-danger-700 text-[14px]" v-if="required">*</span></label>
    <div class="flex items-center relative"> 
      <div class="mr-2" v-if="prepend" @click="$emit('click:prepend')">
        <mdicon :name="prepend" size="22" :class="[IconClasses, {'cursor-pointer' : clickPrepend }]"></mdicon>
      </div>    
      <input
        class="w-full py-2 px-3 text-gray-700 text-[14px] leading-tight focus:outline-none focus:none"
        :class="[{ 'border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-normal focus:outline-none focus:border-primary-500': outline}, {'pr-10' : append}]"
        :type="type"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="focused = true"
        @blur="focused = false"
        v-bind="$attrs"
        :disabled="disabled"
        :required = "required"
      />
      <div class="ml-2 absolute right-2 top-2" v-if="append" @click="$emit('click:append')">
        <mdicon :name="append" size="22" :class="[IconClasses, {'cursor-pointer' : clickAppend }]"></mdicon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      focused: false,
    };
  },
  props: {
    outline : {
      type: Boolean,
      default: true
    },
    required : {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    prepend: {
      type: String,
      default: "",
    },
    append: {
      type: String,
      default: "",
    },
    clickAppend: {
      type: Boolean,
      default: false
    },
    clickPrepend: {
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    IconClasses(){
      return [
        'duration-300 ease transition-all',
        'text-gray-500',
        {'text-primary-700' : this.focused}
      ]
    },
  },
};
</script>
