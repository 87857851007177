<template>
     <div class="gradient-background h-screen">
        <div class="flex justify-center items-center w-full h-full">
            <slot></slot>       
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientLoginLayout',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.base {
    background-image: url('../assets/Background.jpg') !important;
}
.gradient-background {
  background: linear-gradient(to bottom right, #93cbf1, #aff2d8);
}
</style>