<template>
    <div class="">
        <div class="w-full flex flex-col gap-3 text-gray-800 px-5" v-if="data != null">
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                    <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                        <mdicon name="email-outline" class="text-gray-600" size="23"></mdicon>
                    </div>
                    <div class="text-gray-800 font-light text-[14px] tracking-wider">Email:<span class="ml-2">{{data.email}}</span></div>
                </div>   
            </div>    
            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                    <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                        <mdicon name="account-outline" class="text-gray-600" size="23"></mdicon>
                    </div>
                    <div class="text-gray-800 font-light text-[14px] tracking-wider">Username:<span class="ml-2">{{data.username}}</span></div>
                </div>   
            </div>    

            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                    <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                        <mdicon name="door-closed-lock" class="text-gray-600" size="23"></mdicon>
                    </div>
                    <div class="text-gray-800 font-light text-[14px] tracking-wider">Role:<span class="ml-2 whitespace-nowrap">{{data.role.role}}</span></div>
                </div>   
            </div>   
            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                    <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                        <mdicon name="office-building-marker-outline" class="text-gray-600" size="23"></mdicon>
                    </div>
                    <div class="text-gray-800 font-light text-[14px] tracking-wider flex justify-start items-center gap-2"><div class="whitespace-nowrap">Cross Branch:</div><mdicon :name="data.cross_branch ? 'check' : 'close'" size="20" class="text-gray-700"></mdicon></div>
                </div>   
            </div>    

            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                    <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                        <mdicon name="alert-circle-outline" class="text-gray-600" size="23"></mdicon>
                    </div>
                    <div class="text-gray-800 font-light text-[14px] tracking-wider flex justify-start items-center gap-2"><div class="whitespace-nowrap">Status:</div><v-chip size="small">{{ data.active_status }}</v-chip> </div>
                </div>   
            </div>    
                            
        </div>
    </div>
</template>

<script>
export default {    
    name: 'PadmaErpUser',
    props : ['data'],
    data() {
        return {

        };
    },

    mounted() {
    },

    methods: {
       
    },
};
</script>

<style lang="scss" scoped>

</style>