<template>
    <div>
        <sevenDayNotif :data="allData" @close="handleNotif"></sevenDayNotif>
    </div>
</template>

<script>
import sevenDayNotif from './component/sevenDayNotif.vue';
export default {
    name: 'PadmaErpAllNotification',
    props : ['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        handleNotif(){
           this.$emit('close')
        }
    },

    computed : {
        allData(){           
            let employeeId = this.loggedInUser.employee_id
            return this.data.filter(item => {
                
                if(item.assign === employeeId){                   
                    return true;                    
                } else {
                    if (item.mentions.some(mention => mention.employee_id === employeeId)) {
                        return true;
                    }
                }                
                return false;
            });
        }
    },

    components : {
        sevenDayNotif
    }
};
</script>

<style lang="scss" scoped>

</style>