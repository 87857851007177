<template>
    <div>
      <slot name="activator" :props="{ onClick: emitUpdateDialog }"></slot>
      <transition name="fade" mode="out-in">
        <div v-if="isOpen" class="fixed inset-0 z-[998] flex justify-center items-center overflow-y-auto h-screen px-10 pb-20">
          <div class="fixed inset-0 bg-black opacity-50"></div>
          <div class="relative mt-10 max-h-screen p-10">
            <!-- Terapkan kelas animasi pada elemen di dalam slot -->
            <div :class="transitionClass">
              <slot></slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PadmaErpVDialog',
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      animated : {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {};
    },
    computed: {
      isOpen: {
        get() {
          return this.modelValue;
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue);
        },
      },
      transitionClass() {
        if (this.isOpen) {
            return this.animated ? 'bounce-enter-active' : '';
          } else {
            return 'bounce-leave-active';
          }          
      },
    },
    watch: {
      modelValue(newValue) {
        this.isOpen = newValue;
      },
    },
    methods: {
      emitUpdateDialog() {
        this.$emit('update:modelValue', !this.modelValue);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped="scoped">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  /* Define the bounce animation */
  @keyframes bounce {
    0% {
      transform: scale(0);
      opacity: 0;
    }
  
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the bounce animation to the slot content */
  .bounce-enter-active {
    animation: bounce 0.7s;
  }
  
  @keyframes bounce-out {
    0% {
      transform: scale(1);
      opacity: 1;
    }
  
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
  
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  
  /* Apply the bounce-out animation to the slot content when closing */
  .bounce-leave-active {
    animation: bounce-out 0.5s;
  }
  </style>
  