<template>
  <div>
    <label class="flex justify-start items-center mb-4 relative w-full">
      <input type="checkbox" class="hidden" :disabled="disabled" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)">
      <div :class="{
          'bg-primary-700 border-none': modelValue,
          'bg-transparent border-2 border-gray-400': !modelValue
        }" class="relative flex items-center justify-center w-[20px] h-[20px] rounded focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
        <svg class="w-3 h-3 text-white pointer-events-none" :class="{ 'opacity-0': !modelValue }" fill="none" viewBox="0 0 12 12">
          <path d="M4.5 8.25L1.5 5.25L0 6.75L4.5 11.25L12 3.75L10.5 2.25L4.5 8.25Z" fill="currentColor"></path>
        </svg>
      </div>
      <div class="relative w-full">
        <span class="ml-2 text-gray-600 text-sm">{{ label }}</span>
        <div v-if="subLabel" class="absolute text-sm font-normal text-gray-500 left-2">{{ subLabel }}</div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    subLabel : {
      type : [Boolean, String],
      default : false
    }
  }
}
</script>
