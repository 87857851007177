<template>
    <div class="">
        <div class="w-full">
            <div class="text-lg w-full flex gap-2 text-gray-800 mb-2">
                <div>Family Data</div>
                <div class="flex justify-center items-center">
                    <mdicon name="human-male-female-child" class="text-gray-700" size="20"></mdicon>
                </div>               
            </div>

            <div class="table-auto w-full text-gray-800 mb-6">
                <div class="border-b border-gray-300 w-full grid grid-cols-[repeat(5,1fr)] text-gray-700">
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Relation</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Name</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Birth Date</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Job</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Phone Number</div>
                </div>
                <div class="border-b border-gray-300 capitalize w-full grid grid-cols-[repeat(5,1fr)] text-gray-700" v-for="(fam, index) in family" :key="index">
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{fam.relation}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{fam.name}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{fam.birthdate != '' ? moment(fam.birthdate).locale('id').format('LL') : ''}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{fam.job}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{fam.phone}}</div>
                </div>
            </div>

            <div class="text-lg w-full flex gap-2 text-gray-800 mb-2">
                <div>Emergency Contact</div>
                <div class="flex justify-center items-center">
                    <mdicon name="car-brake-alert" class="text-gray-700" size="20"></mdicon>
                </div>               
            </div>

            <div class="table-auto w-full text-gray-800 mb-6">
                <div class="border-b border-gray-300 w-full grid grid-cols-[repeat(3,1fr)] text-gray-700">
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Relation</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Name</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Birth Date</div>
                </div>
                <div class="border-b border-gray-300 capitalize w-full grid grid-cols-[repeat(3,1fr)] text-gray-700" v-for="(em, index) in emergency" :key="index">
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{em.relation}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{em.name}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{em.phone}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
export default {
    name: 'PadmaErpFamily',
    props: ['data'],
    data() {
        return {
            moment : moment
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed:{
        family(){
            return this.data.family
        },
        emergency(){
            return this.data.emergency
        }
    }
};
</script>

<style lang="scss" scoped>

</style>