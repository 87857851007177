<template>
    <div class="menu-item relative w-full" :class="{expanded: expanded}">
        <router-link :to="url"  class="label h-10 my-1 pr-[15px] text-gray-700 whitespace-nowrap group hover:cursor-pointer w-full flex justify-between primary-800 select-none box-border transition-all ease-in-out duration-300" 
            :style="{paddingLeft: depth * 35 + 15 + 'px'}"
            v-if="checkpermited(requirePermission,modules, permission)"
            >
            <div class="flex gap-2 items-center w-full px-2 rounded-lg duration-300 ease-out" :class=" this.$route.meta.group == group ? 'bg-primary-100 text-primary-800 font-medium' : 'hover:bg-gray-200 text-gray-700'  ">
                <mdicon v-if="icon" :name="icon" class="" :size="isExpanded ? '20' : '24'"></mdicon>
                <span class="text-[14px]" :class="isExpanded ? '' : 'md:hidden'">{{ label }}</span>
            </div>
       </router-link>
    </div>
</template>

<script>
import store from '@/store';
import { computed } from 'vue';
export default {
    name: 'PadmaErpMenuItem',
    props:{
        label:{
            type: String,
            required: true
        },
        icon:{
            type: String
        },
        depth:{
            type: Number,
            required: true
        },
        data:{
            type: Array
        },
        url:{
            type: [String, Boolean]
        },
        modules:{
            type: String
        },
        requirePermission:{
            type : Boolean
        },
        permission:{
            type: String
        },
        group:{
            type: String
        }
    },
    computed:{
        showLabel(){
            return this.isExpanded
        }
    },
    data() {
        return {
            showChild : false,
            expanded:false,
            containerHeight: 0
        };
    },

    mounted() {
        
    },

    methods: {
    },
    computed : {
        isExpanded(){
            return store.state.menuExpanded
        }
    }
};
</script>

<style lang="scss" scoped>
.menu-item{
    .item-container{
        left: calc(100% + 6px);
        &.small-menu{
            width: max-content;
            .label{
                width: 100% !important;
                padding-left: 20px !important;
            }

        }
    }
}

</style>