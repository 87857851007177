<template>
  <div class="relative inline-block">
    <div @click="togglePopup">
      <v-btn icon="dots-vertical" size="small"></v-btn>
    </div>
    <div v-if="showPopup" class="popup" @click="closePopup">
      <div class="flex justify-start flex-col items-start gap-[6px]">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    closePopup() {
      this.showPopup = false;
    }
  }
};
</script>

<style>
.popup {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-100%, 100%);
  background-color: #fff;
  padding: 10px;
  z-index: 999;
  min-width: 130px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style>
