<template>
<div class="align-middle inline-block min-w-full w-full overflow-x-auto">
    <slot name="top"></slot>
    <div class="flex flex-col md:flex-row justify-between gap-4 mb-2 items-center">
        <slot name="extra"></slot>
        <div class="relative w-full flex gap-2 justify-end items-center" v-if="filtered">
            <v-text-field type="text" placeholder="Search...." v-model="searchValue" append="magnify" class="w-full"></v-text-field>
        </div>
    </div>
    <div class="w-full overflow-hidden" v-if="loading">
        <div class="meter bg-gray-200 h-1">
            <span style="width:100%;"><span class="progress bg-gradient-to-r from-primary-500 to-primary-500"></span></span>
        </div>
        <div class="flex justify-center items-center text-gray-500 pt-4 pb-5 text-sm capitalize">
            Loading.... Please Wait
        </div>
    </div>
    <div class="border-white pt-2 overflow-x-auto w-full" v-if="!loading">
        <div class="table-responsive pb-4">
            <table class="table border-collapse w-full md:w-[calc(100%-10px)] table-x">
                <thead>
                    <tr>
                        <td v-if="check && currentPageData.length" class=" w-auto md:w-16 border-b md:border border-gray-300 px-[10px] md:py-2 text-center text-[13px] leading-normal text-gray-700" scope="col">
                            <div class="w-full h-full flex justify-start md:justify-center items-center">
                                <input type="checkbox" name="checkall" id="" v-model="selectAll" @change="toggleAll" class="w-4 h-4">
                            </div>
                        </td>
                        <td scope="col" v-for="header in headers" :key="header.key" :class="{ 'cursor-pointer': header.sortable , 'whitespace-nowrap': !header.wrap, 'md:min-w-[200px]': header.wrap }" @click="sortTable(header)" class="border-b md:border border-gray-300 px-[10px] md:py-2 text-center text-[13px] leading-normal text-gray-700">
                            <div class="flex justify-center items-center">
                                {{ header.label }}
                                <span v-if="header.sortable">
                                    <mdicon class="text-gray-500" name="arrow-up" size="20" v-show="isSortedBy(header.key) && sortDirection === 'asc'"></mdicon>
                                    <mdicon class="text-gray-500" name="arrow-down" size="20" v-show="isSortedBy(header.key) && sortDirection === 'desc'"></mdicon>
                                </span>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-show="check">
                        <td data-label="select all" class="px-[10px] md:hidden text-[13px] leading-normal md:py-[5px] ">
                            <div class="w-full h-full flex justify-start md:justify-center items-center">
                                <input type="checkbox" name="checkall" id="" v-model="selectAll" @change="toggleAll" class="w-4 h-4">
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(item, itemIndex) in currentPageData" :key="itemIndex" class="hover:bg-gray-100 hover:shadow">
                        <td class="px-[10px] py-[5px] text-[13px] leading-normal border-b md:border  border-gray-300" data-label="select" v-show="check">
                            <div class="w-full h-full flex justify-start md:justify-center items-center">
                                <input type="checkbox" name="checkall" id="" v-model="selectedRows" :value="item" class="w-4 h-4">
                            </div>
                        </td>
                        <td v-for="header in headers" :key="header.key" :class="[
                                        'px-[10px] py-[5px] text-[13px] leading-normal border-b md:border border-gray-300',
                                        { 'whitespace-nowrap': !header.wrap },
                                        { 'md:max-w-[200px] whitespace-normal': header.wrap },
                                    ]" :data-label="header.label">
                            <slot :name="header.alias ? header.alias : header.key" :item="item">
                                <div class="flex justify-start item-center gap-2">
                                    <mdicon name="calendar" size="16" class="text-gray-700" v-if="(header.type == 'Date' || header.type == 'Datetime') && item[header.key] != null"></mdicon>
                                    <div>
                                        {{ converdata(getCellValue(item, header.key),header.type) }}
                                    </div>
                                </div>
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="flex justify-end items-center gap-8 px-4">
        <div class="per-page-container flex flex-col md:flex-row justify-start items-center gap-2 ">
            <div for="per-page" class="text-sm font-medium text-gray-700 whitespace-nowrap hidden md:block">Items per page :</div>
            <v-select :options="perPageOptions" v-model="perPage" class="mt-4"></v-select>
        </div>
        <div class="flex justify-end items-center gap-5">
            <div class="flex justify-end">
                <span class="text-base text-gray-700 hidden md:block">
                    {{ startRange }}
                    -
                    {{ endRange }}
                    of
                    {{ totalItems }}
                </span>
            </div>
            <div class="pagination-container justify-end flex gap-2 items-center">
                <button :disabled="currentPage === 1" @click="currentPage--">
                    <mdicon name="chevron-left" :class="currentPage === 1 ? 'text-gray-300' : 'text-gray-700'"></mdicon>
                </button>
                <span class="text-base font-normal text-gray-700">{{ currentPage }}</span>
                <button :disabled="currentPage === pageCount" @click="currentPage++">
                    <mdicon name="chevron-right" :class="currentPage === pageCount ? 'text-gray-300' : 'text-gray-700'"></mdicon>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'data-table',
    props: {
        filtered: {
            type: Boolean,
            default: false
        },
        check: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        data: Array,
        headers: Array,
        perPageOptions: {
            type: Array,
            default: () => [{
                value: 10,
                label: 10
            }, {
                value: 25,
                label: 25
            }, {
                value: 50,
                label: 50
            }, {
                value: 100,
                label: 100
            }]
        },
        moment: moment,
    },
    data() {
        return {
            sortKey: '',
            sortDirection: 'asc',
            searchValue: '',
            perPage: this
                .perPageOptions[0]
                .value,
            currentPage: 1,
            selectedRows: []
        };
    },
    computed: {
        selectAll: {
            get() {
                return this.selectedRows.length === this.data.length
            },
            set(value) {
                if (value) {
                    this.selectedRows = this.data.map(row => row)
                } else {
                    this.selectedRows = []
                }
            }
        },

        sortableHeaders() {
            return this
                .headers
                .filter((header) => header.sortable);
        },
        sortedData() {
            const data = [...this.data];
            if (this.sortKey) {
                const direction = this.sortDirection === 'asc' ? 1 : -1;
                data.sort((a, b) => {
                    const valueA = this.getCellValue(a, this.sortKey);
                    const valueB = this.getCellValue(b, this.sortKey);

                    if (valueA < valueB) return -direction;
                    if (valueA > valueB) return direction;
                    return 0;
                });
            }
            return data;
        },

        filteredData() {
            let data = [...this.sortedData];
            if (this.searchValue) {
                const searchRegex = new RegExp(this.searchValue, 'i');
                data = data.filter((item) => {
                    const hasMatchingValue = this.checkObjectForValue(item, searchRegex);
                    return hasMatchingValue;
                });
            }
            return data;
        },

        currentPageData() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this
                .filteredData
                .slice(start, end);
        },
        pageCount() {
            return Math.ceil(this.filteredData.length / this.perPage);
        },
        totalItems() {
            return this.filteredData.length
        },
        totalPages() {
            return Math.ceil(this.totalItems / this.perPage)
        },
        startRange() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endRange() {
            const endRange = this.currentPage * this.perPage
            return endRange > this.totalItems ?
                this.totalItems :
                endRange
        }
    },
    methods: {

        checkObjectForValue(obj, searchRegex) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (typeof value === 'object') {
                        // Recurse if the value is an object
                        const hasMatchingValue = this.checkObjectForValue(value, searchRegex);
                        if (hasMatchingValue) {
                            return true;
                        }
                    } else if (typeof value === 'string' && searchRegex.test(value)) {
                        // Check the value if it matches the search pattern
                        return true;
                    }
                }
            }
            return false;
        },

        getCellValue(item, key) {
            if (key.includes(".")) {
                let keys = key.split(".");
                let value = item;
                for (let i = 0; i < keys.length; i++) {
                    if (value[keys[i]] == null) {
                        value = '';
                    } else {
                        value = value[keys[i]];
                    }
                }
                return value;
            } else {
                return item[key];
            }
        },
        converdata(value, type = 'String') {
            if (type == 'Number') {
                return this.numberWithCommas(Math.round(value, 2))
            } else if (type == 'Date') {
                if (value) {
                    return moment(value).format('DD MMM YYYY');
                } else {
                    return '';
                }
            }   else if (type == 'Datetime') {
                if (value) {
                    return moment(value).format('DD MMM YYYY HH:mm:ss');
                } else {
                    return '';
                }
            } else if (type === 'Decimal') {
                const formattedValue = parseFloat(value).toFixed(2);
                return this.numberWithCommas(formattedValue);
            } else {
                return value
            }
        },
        numberWithCommas(x) {
            if (x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return '-'
            }
        },
        toggleAll() {
            if (this.selectAll) {
                this.selectedRows = this.data.map(row => row)
            } else {
                this.selectedRows = []
            }
        },

        sortTable(header) {
            if (!header.sortable) return;

            if (this.isSortedBy(header.key)) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortKey = header.key;
                console.log('shor key is....' + this.sortKey)
                this.sortDirection = 'asc';
            }
        },
        isSortedBy(key) {
            return this.sortKey === key;
        }
    },
    components: {

    },
    watch: {
        perPage: function (newValue) {
            this.currentPage = 1;
            return newValue
        },
        selectedRows: function () {
            const arrayObjects = Object.values(this.selectedRows).map(value => ({ value }));
            this.$emit('selected', arrayObjects)
        }
    }
};
</script>

<style lang="scss" scoped>
.table-x {
    position: relative;
    margin-left: 8px;

    &::before {
        content: '';
        position: absolute;
        left: -8px;
        top: 0;
        height: 100%;
        width: 8px;
        @apply bg-primary-500 rounded-l-lg;
    }
}

.meter {
    position: relative;
    overflow: hidden;
}

.meter span {
    display: block;
    height: 100%;
}

.progress {
    animation: progressBar .8s ease-in-out infinite;
    animation-fill-mode: both;
}

@keyframes progressBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.table-responsive {
    overflow-x: auto;
}

@media (max-width: 767.98px) {
    .table-x {
        position: relative;
        margin-left: 8px;

        &::before {
            display: none;
            ;
        }
    }

    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive table {
        width: 100%;
    }

    .table-responsive thead {
        display: none;
    }

    .table-responsive tbody {
        display: block;
        width: 100%;
    }

    .table-responsive tbody tr {
        display: block;
        width: 100%;
        margin-bottom: 15px;
    }

    .table-responsive tbody td {
        display: block;
        width: 100%;
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table-responsive tbody td:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        text-align: left;
        @apply text-sm text-gray-700;
    }

    .table-responsive tbody td:last-child {
        border-bottom: none;
    }

    .table-responsive tbody td::before {
        content: attr(data-label);
        float: left;
        width: 50%;
    }

    .table td {
        padding: 10px 0;
    }
}

::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}

::-webkit-scrollbar-track {
    @apply bg-gray-300 bg-opacity-30;
    // background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    @apply bg-gray-300 bg-opacity-50;
    // background: rgb(185, 185, 185); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300 bg-opacity-100;
    // background: rgb(182, 182, 182); 
}
</style>
