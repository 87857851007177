import { createStore } from 'vuex';
import employee from './employee';


export default createStore({
  state: {    
    privilege:[],
    notifications : [],
    messages : [],
    menuExpanded: true,
    menuTree : [
      { 
          label : 'Dashboard',
          url : '/',
          icon: 'view-dashboard-outline',
          children : [],
          requirePermission: false,
          group : 'home'
      },
      {
          label : 'Employee',
          url : '/employee',
          icon: 'account-group-outline',
          module : 'User',
          requirePermission: true,
          permission:'Create',
          children : [],
          group : 'employee'
      },
      {
          label : 'User Management',
          url : '/user-management',
          icon: 'account-cog-outline',
          module: 'User-Management',
          requirePermission: true,
          permission : 'Create',
          children : [],
          group : 'user-management'
      },
      {
          label : 'Role Management',
          url : '/role-management',
          icon: 'key-outline',
          module: 'Role-Management',
          requirePermission: true,
          permission : 'Create',
          children : [],
          group : 'role-management'
      },
      {
          label : 'Payroll',
          url : '/payroll',
          icon: 'cash-plus',
          module: 'Payroll',
          permission : 'Create',
          requirePermission: true,
          children : [],
          group : 'payroll'
      },
      {
          label : 'Payroll Configuration',
          url : '/payroll-configuration/tab=tax-grade',
          icon: 'book-cog-outline',
          module: 'Payroll-Configuration',
          requirePermission: true,
          permission : 'Create',
          children : [],
          group : 'payroll-configuration'
      },
      {
          label : 'Company Setup',
          url : '/company-setup/tab=branch',
          icon: 'domain',
          module: 'Company-Setup',
          requirePermission: true,
          permission : 'Create',
          children : [],
          group : 'company-setup'
      },
      {
          label : 'Parameter',
          url : '/parameter/tab=religion',
          icon: 'cog-outline',
          module: 'Parameter',
          requirePermission: true,
          permission : 'Create',
          children : [],
          group : 'parameter'
      },
      {
          label : 'Employee',
          url : '/employee',
          icon: 'account-group-outline',
          module: 'Employee',
          requirePermission: true,
          permission : 'Read',
          children : [],
          group : 'employee'
      },
      {
          label : 'Payroll Report',
          url : '/payroll-report/tab=payroll-report',
          icon: 'file-chart-outline',
          module: 'Payroll-Report',
          requirePermission: true,
          permission : 'Read',
          children : [],
          group : 'payroll-report'
      },
      {
          label : 'Time Management',
          url : '/time-management/tab=schedule',
          icon: 'clock-time-two-outline',
          module: 'Time-Management',
          requirePermission: true,
          permission : 'Read',
          children : [],
          group : 'time-management'
      },
      {
          label : 'Target & Review Setting',
          url : '/target-review-setting/tab=review-circle',
          icon: 'application-cog-outline',
          module: 'Target-and-Review-Setting',
          requirePermission: true,
          permission : 'Read',
          children : [],
          group : 'target-and-review-setting'
      },
      {
          label : 'Target & Review',
          url : '/target-review/tab=dashboard',
          icon: 'bullseye-arrow',
          module: 'Target-and-Review',
          requirePermission: true,
          permission : 'Read',
          children : [],
          group : 'target-review'
      },
      {
          label : 'My Payroll',
          url : '/my-payroll/tab=payroll-report',
          icon: 'file-account-outline',
          module: 'My-Payroll',
          children : [],
          group : 'my-payroll'
      },
      {
          label : 'Time Off',
          url : '/time-off/tab=time-off',
          icon: 'calendar-clock',
          requirePermission: true,
          permission : 'Read',
          module: 'Time-Off',
          children : [],
          group : 'time-off'
      },
      {
          label : 'Overtime',
          url : '/overtime/tab=overtime',
          icon: 'clock-plus-outline',
          requirePermission: true,
          permission : 'Read',
          module: 'Overtime',
          children : [],
          group : 'overtime'
      },
      {
          label : 'Inventory Management',
          url : '/inventory-management/inventory-stock',
          icon: 'format-list-numbered-rtl',
          requirePermission: true,
          permission : 'Item',
          module: 'Inventory',
          children : [],
          group : 'inventory'
      },
      {
          label : 'Activity',
          url : '/activity/my-activity',
          icon: 'clipboard-list-outline',
          requirePermission: true,
          permission : 'Read',
          module: 'Activity',
          children : [],
          group : 'activity'
      },
      {
          label : 'Letter',
          url : '/letter/tabs=letter',
          icon: 'mailbox-open-up-outline',
          requirePermission: true,
          permission : 'Input',
          module: 'Letter',
          children : [],
          group : 'letter'
      },
      {
          label : 'Letter Approval',
          url : '/letter-approval/tabs=letter-approval',
          icon: 'email-check-outline',
          requirePermission: true,
          permission : 'Approval',
          module: 'Letter',
          children : [],
          group : 'letter-approval'
      },
        { 
            label : 'Disposition',
            url : '/disposition',
            icon: 'clipboard-list-outline',
            requirePermission: false,
            children : [],
            group : 'disposition'
        },
        { 
            label : 'Delegation',
            url : '/delegation/tabs=delegated',
            icon: 'swap-horizontal',
            requirePermission: false,
            children : [],
            group : 'delegation'
        },
      {
          label : 'Company Policies',
          url : '/company-policies/policies',
          icon: 'book-open-page-variant-outline',
          requirePermission: true,
          permission : 'Read',
          module: 'Company-Policies',
          children : [],
          group : 'company-policies'
      },
      {
        label : 'Helpdesk Params',
        url : '/helpdesk-params/group-agent',
        icon: 'ticket-confirmation-outline',
        requirePermission: true,
        permission : 'Read',
        module: 'Helpdesk-Params',
        children : [],
        group : 'helpdesk-params'
    },
    {
        label : 'Helpdesk',
        url : '/helpdesk/dashboard',
        icon: 'face-agent',
        requirePermission: true,
        permission : 'Read',
        module: 'Helpdesk',
        children : [],
        group : 'helpdesk'
    },
    {
        label : 'System Administrator',
        url : '/system-administrator',
        icon: 'security',
        requirePermission: true,
        permission : 'Administrator',
        module: 'Administrator',
        children : [],
        group : 'administrator'
    }
    ],
   
  },
  getters: {
   
    getalert: state => {
      return state.alert
    },
    getnotification: state => {
      return state.alert
    },
    getmessage: state => {
      return state.messages
    },
    getMenuExpanded: state => {
      return state.menuExpanded
    },   
  },
  mutations: {
    updaterole(state, payload){
        state.privilege = payload
    },
    update_notification(state, payload){
        state.notifications = payload
    },
    update_messages(state, payload){
      state.messages = payload
    },
    updateMenuExpanded(state){
      state.menuExpanded = !state.menuExpanded
    },

  },
  actions: {
    
  },
  modules: {  
    employee,
  }
})
