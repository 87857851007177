<template>
        <div class="w-full overflow-x-hidden flex flex-col justify-start items-start md:items-center">           
            <div class="bg-white h-screen md:h-[calc(100vh-50px)] w-full overflow-hidden md:rounded-lg drop-shadow-lg shadow border border-gray-200 transition-all duration-300 ease-in-out" :class="isExpanded ? 'md:w-[260px]' : 'md:w-auto'">
                <div class="pb-10 h-full overflow-y-auto py-4" :class="isExpanded ? 'pr-4' : ''">
                    <MenuItem
                    v-for="(item,index) in MenuTree" 
                    :key="index"
                    :label="item.label"
                    :icon="item.icon"
                    :data="item.children"
                    :depth="0"
                    :url="item.url"
                    :requirePermission="item.requirePermission"
                    :modules="item.module"
                    :permission="item.permission"
                    :group="item.group"
                    >
                    </MenuItem>
                </div>
            </div> 
        </div>
</template>

<script>

import MenuItem from './MenuItem'
import store from '@/store';

export default {
    name: 'PadmaErpSidebar',
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    components:{
        MenuItem
    },
    computed : {
        isExpanded(){
            return store.state.menuExpanded
        },
        MenuTree (){
            return store.state.menuTree
        }
    }
};
</script>

<style lang="scss" scoped>

/* width */
::-webkit-scrollbar {
width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    @apply bg-gray-300 bg-opacity-30;
// background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
 @apply bg-gray-300 bg-opacity-50;
// background: rgb(185, 185, 185); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300 bg-opacity-100;
// background: rgb(182, 182, 182); 
}

</style>