<template>
    <div class="">
        <div class="w-full flex flex-col gap-3 text-gray-800 px-5">
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="bank-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Bank Name:<span class="ml-2">{{data.banks.bank}}</span></div>
            </div>   
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="checkbook" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Account Number:<span class="ml-2">{{data.bank_account_number}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="account-credit-card-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Account Holder:<span class="ml-2">{{data.bank_account_holder}}</span></div>
            </div>    
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="text-box-multiple-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">NPWP Number:<span class="ml-2">{{data.npwp_number}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="book-account-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">PTKP Status:<span class="ml-2">{{data.ptkps.ptkp_status}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calculator-variant-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Tax Method:<span class="ml-2">{{data.tax_method}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="check-underline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Tax Salary:<span class="ml-2">{{data.tax_salary}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="book-minus-multiple-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Tax Deduction:<span class="ml-2">{{data.tax_deductions.label}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="card-bulleted-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">BPJS Ketenagakerjaan Number:<span class="ml-2">{{data.bpjstk_number}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="card-bulleted" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">BPJS Ketenagakerjaan NPP:<span class="ml-2">{{data.npp_bpjstk}} - {{data.npp_label}}</span></div>
            </div>     
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calendar-plus" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">BPJS Ketenagakerjaan Date:<span class="ml-2">{{moment(data.bpjstk_date).locale('id').format('LL')}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="account-injury-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">BPJS Kesehatan Number:<span class="ml-2">{{data.bpjs_kes_number}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="family-tree" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">BPJS Kesehatan Family:<span class="ml-2">{{data.bpjs_kes_family}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calendar-clock" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Payroll Schedule:<span class="ml-2">{{data.payroll_schedule}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="table-clock" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider flex gap-2 justify-start items-center"><span>Allow Overtime:</span><mdicon :name="data.overtime == 'true' ? 'check' : 'close'"  class="text-gray-700"></mdicon></div>
            </div>            
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
export default {
    name: 'PadmaErpFinance',
    props: ['data'],
    data() {
        return {
            moment : moment
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>