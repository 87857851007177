<template>
  <div class="gap-2 flex flex-col my-4">
    <label v-if="label" class="text-sm font-medium text-gray-700" >{{ label }}</label>
    <div :class="[`radio-group flex gap-4`,[direction === 'vertical' ? 'flex-col' : 'flex-row']]">
      <label
        v-for="(option, index) in options"
        :key="option.value"
        class="flex gap-2 items-center"
      >
        <input
          :disabled="disabled"
          type="radio"
          :value="option.value"
          v-model="selectedOption"
          :name="name"
          :id="`radio-${index}`"
          class="hidden"
          @change="emitSelectedOption"
        />
        <div class="radio-button relative flex items-center justify-center rounded-full w-[19px] h-[19px] border border-gray-400 transition duration-150 ease-in-out"
            :class="{ 'border-primary-500 bg-primary-500': selectedOption === option.value, 'border-gray-400': selectedOption !== option.value, 'cursor-not-allowed' : disabled }">
          <div class="radio-dot-checked rounded-full bg-white w-[20px] h-[20px] transition duration-150 ease-in-out"
              v-if="selectedOption === option.value">
          </div>
        </div>
        <span class="text-[14px] text-gray-600 whitespace-nowrap">{{ option.label }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OptionsRadio',
  props: {
    disabled : {
      type : Boolean,
      default : false
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: false,
    },
    direction: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['vertical', 'horizontal'].includes(value),
    },
    label: {
      type: [String,Boolean],
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedOption: this.modelValue || null,
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedOption = newValue;
    },
    selectedOption(newValue) {
      this.$emit('update:modelValue', newValue);
    },
  },
  methods: {
    emitSelectedOption() {
      this.$emit('update:modelValue', this.selectedOption);
    },
  },
});
</script>

<style>
.radio-group .radio-button .radio-dot-checked {
  width: 10px;
  height: 10px;
  @apply shadow-primary-500 shadow-sm;
  border-radius: 50%;
}
</style>
