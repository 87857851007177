<template>
    <div class="flex items-center justify-start gap-[10px]">
      <div v-if="$slots.leading">
        <slot name="leading"></slot>
      </div>
      <div class="flex flex-col justify-start items-start flex-grow w-full">
        <h3 class="text-[13px] font-medium text-gray-700" v-if="$slots.title">
          <slot name="title"></slot>
        </h3>
        <p class="text-[11px] text-gray-500" v-if="$slots.subtitle">
          <slot name="subtitle"></slot>
        </p>
      </div>
      <div v-if="$slots.trailing" class="ml-auto">
        <slot name="trailing"></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ListTile'
  }
  </script>
  
  <style scoped>
  </style>
  