<template>
    <div class="">
        <div class="w-full flex flex-col gap-3 text-gray-800 px-5" v-if="data != null">
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="account" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Name:<span class="ml-2">{{data.employee_name}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="email-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Email:<span class="ml-2">{{data.email}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="phone" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Phone:<span class="ml-2">{{data.phone}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="hospital-marker" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Place of birth:<span class="ml-2">{{data.birth_place}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calendar-account-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Birth date:<span class="ml-2">{{moment(data.birth_date).locale('id').format('LL')}}</span></div>
            </div>              
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="gender-male-female" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Gender:<span class="ml-2">{{data.gender}}</span></div>
            </div>  
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="ring" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Marital Status:<span class="ml-2">{{data.marital_statuses.marital_status}}</span></div>
            </div>             
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="water" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Blood Type:<span class="ml-2">{{data.blood_types?.blood_type}}</span></div>
            </div>             
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="hands-pray" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Religion:<span class="ml-2">{{data.religions.religion}}</span></div>
            </div>             
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="school-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Last Education:<span class="ml-2">{{data.last_educations.education}}</span></div>
            </div>             
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="card-account-details-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">ID Number:<span class="ml-2">{{data.id_number}}</span></div>
            </div>             
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="credit-card-search-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">ID Type:<span class="ml-2">{{data.id_types.id_type}}</span></div>
            </div>             
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="credit-card-remove-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">ID Expired:<span class="ml-2">{{data.id_permanent == 'true' ? 'Permanent': moment(data.id_expired).locale('id').format('LL')}}</span></div>
            </div>  
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="mailbox-up" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Postal Code:<span class="ml-2">{{data.postal_code}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="map-marker-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Citizen ID Address:<span class="ml-2">{{data.citizen_id_address}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="map-marker-radius" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Residential Address:<span class="ml-2">{{data.residential_address}}</span></div>
            </div>           
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
export default {
    name: 'PadmaErpGeneral',
    props: ['data'],
    data() {
        return {
            moment : moment
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>