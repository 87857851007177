<template>
    <nav class="h-[50px] gap-2 flex justify-between items-center px-2 md:px-5 z-10">   
        <div class="flex justify-start items-center gap-2">
            <div class=" hidden md:block">
                <div class="p-1 rounded-lg hover:bg-primary-200 duration-300 ease-in-out cursor-pointer" @click="handleExpandedMenu">
                    <mdicon name="dots-grid" size="30"></mdicon>
                </div>
            </div>
            <div class="mt-5 hidden md:block">
                <img :src="logo" alt="" width="180" class="mb-5">
            </div>
            <div class="flex md:hidden justify-center gap-2 md:gap-5 items-center h-full">
                <v-btn icon="menu" @click="$emit('menu')"></v-btn>
            </div> 
        </div>                                
        <div class="flex md:w-full justify-end items-center gap-1 h-full ">                        
            <div class="relative hover:bg-gray-300 py-2 px-3 rounded duration-200 ease cursor-pointer"  @click="$emit('notif')">
                <div class="absolute right-0 top-0 bg-danger-600 text-white shadow drop-shadow text-[11px] rounded-full w-4 h-4 p-1 flex justify-center items-center" v-if="unreadData.length">
                    <span>
                        {{ unreadData.length }}
                    </span>
                </div>
                <v-btn icon="bell-outline" size="small" class="text-gray-600"></v-btn>
            </div>
            <div class="relative hover:bg-gray-300 py-2 px-3 rounded duration-200 ease cursor-pointer"  @click="$emit('message')">
                <div class="absolute right-0 top-0 bg-danger-600 text-white shadow drop-shadow text-[11px] rounded-full w-4 h-4 p-1 flex justify-center items-center" v-if="unreadMessage.length">
                    <span>   
                        {{ unreadMessage.length }}             
                    </span>
                </div>
                <v-btn icon="email-outline" size="small" class="text-gray-600"></v-btn>
            </div>
            <div class="h-full ml-3 hover:bg-gray-300 duration-300 ease-out flex justify-center gap-2 items-center pl-2 md:pl-5 border-l border-gray-400 cursor-pointer" @click="$emit('info')">
                <div class="h-7 w-7 md:w-8 md:h-8 rounded-full overflow-hidden">
                    <img  v-if="loggedInUser.employee.photo_url != null" :src="loggedInUser.employee.photo_url" class="w-full h-full object-cover object-center" alt="">
                    <div v-if="loggedInUser.employee.photo_url == null" class="w-full h-full bg-primary-700 text-white flex justify-center items-center text-xs tracking-widest font-bold">
                        {{ getInitials(loggedInUser.employee.employee_name) }}
                    </div>                            
                </div>
                <mdicon name="chevron-down" class="text-gray-700" size="20"></mdicon>
            </div>
        </div>
    </nav>
</template>

<script>
import logo from '@/assets/logo.png'
import store from '@/store';
export default {
    name: 'PadmaErpNavigation',

    data() {
        return {
            logo:logo
        };
    },

    mounted() {
        this.getNotif();
        this.getMessage();
    },

    methods: {
        handleExpandedMenu(){
            store.commit('updateMenuExpanded')
        },
        getNotif(){
            this.loadingdata = true;
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json'
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/notification/get/${this.loggedInUser.employee_id}`,headers).then((response)=>{
                const newNotifications = response.data;
                store.commit('update_notification', newNotifications);
            }).catch((error)=>{
                
            }).finally(() => {
                this.loadingdata = false;
            })                    
        },
        getMessage(){
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json'
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/message/${this.loggedInUser.username}/unread`,headers).then((response)=>{
                const messages = response.data;
                store.commit('update_messages', messages);
            }).catch((error)=>{
            })
        },
    },

    computed : {
        filteredData() {
            let notif = this.$store.state.notifications;
            // let employeeId = this.loggedInUser.employee_id
            return notif;
        },

        unreadData(){
            let employeeId = this.loggedInUser.employee_id
            return this.filteredData.filter(item => {                
                if(item.assign === employeeId){
                    if(!item.read || item.read === '0'){
                        return true;
                    }
                } else {
                    if (item.mentions.some(mention => mention.employee_id === employeeId && !mention.read)) {
                        return true;
                    }
                }                
                return false;
            });
        },

        unreadMessage(){            
            let messages = this.$store.state.messages;
            
            return messages
        },


    }
};
</script>

<style lang="scss" scoped>

</style>