<template>
    <div>
        <sevenDayNotif :data="unreadData" @close="$emit('close')"></sevenDayNotif>
    </div>
</template>

<script>
import sevenDayNotif from './component/sevenDayNotif.vue';
export default {
    name: 'PadmaErpUnreadNotification',
    props : ['data'],
    data() {
        return {
            
        };
    },

    mounted() {
       
    },

    methods: {
        
    },

    computed : {
        unreadData(){

            let employeeId = this.loggedInUser.employee_id
            return this.data.filter(item => {
                
                if(item.assign === employeeId){
                    if(!item.read || item.read === '0'){
                        return true;
                    }
                } else {
                    if (item.mentions.some(mention => mention.employee_id === employeeId && (!mention.read || mention.read === '0'))) {
                        return true;
                    }
                }                
                return false;
            });
        }
    },

    components : {
        sevenDayNotif
    }
};
</script>

<style lang="scss" scoped>

</style>