<template>
    <span class="relative group cursor-pointer fade-container">
        <span class="text-primary-400 hover:text-primary-500 duration-300 ease-out">
            @nurtaya
        </span>
        <div class="absolute top-[110%] bg-white shadow rounded-lg border p-4 fade">
            <div class="flex justify-between items-center">
                <div>
                    <div class="w-[100px] h-[100px] overflow-hidden rounded-full">
                        <img src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" alt="" class="w-full h-full object-cover object-center">
                    </div>
                </div>
                <div>
                    <div>Nurtaya</div>
                    <div>IT</div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
export default {
    name: 'PadmaErpVMention',
    props:['userdata'],
    data() {
        return {
            isOpen : false
        };
    },

    mounted() {
        
    },

    methods: {
        openProfile(){
            this.isOpen = true;
        },

        closeProfile(){
            this.isOpen = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.fade {
    opacity: 0;
    transform: translateY(50px);
    transition: all ease-in-out 0.3s;
}

.fade-container:hover .fade{
    opacity: 1;
    transform: translateY(0px);
} 
</style>