<template>
  <div :class=" isdark ? 'dark' : '' "> 
    <notifications position="top right" />
    <component :dark="isdark" :is="this.$route.meta.layout || 'div'">
      <router-view :key="$route.fullPath"/>
    </component>
  </div>
</template>

<style lang="scss" scoped>
</style>
<script>
 export default {
    data(){
      return {
        isdark : false
      }
    }
    }
</script>

