<template>
    <div class="flex flex-wrap justify-center">
         <div class="p-4" :class="computedClasses" v-bind="$attrs">
           <div>
               <slot name="card-title"></slot>
           </div>
           <slot name="card-body"></slot>
           <div class="mt-4">
               <slot name="card-action"></slot>
           </div>
         </div>
     </div>
 </template>
 
 <script>
 export default {
     name: 'PadmaErpVCard',
     props: {
         variant: {
             type: String,
             default: 'default'
         },
         overflow : {
            type : Boolean,
            default: true
         }
     },
     data() {
         return {};
     },
     methods: {},
     computed: {
         computedClasses() {
             return [
                 this.variant === 'default' ? 'bg-white' : this.variant,
                 'shadow',
                 'p-4',
                 this.overflow ? 'overflow-hidden' : '',
                 'w-full',
                 'rounded-lg',
             ];
         }
     }
 };
 </script>
 
 <style lang="scss" scoped>
 
 </style>
 