<template>
    <div>
        <transition name="nested" duration="550">   
            <div class="fixed flex justify-center h-screen items-end bg-black bg-opacity-50 z-40 w-full inset-0" v-if="isOpen">
                
                <div class="inner bg-white w-[96vw] h-[96vh] rounded-t-2xl shadow text-gray-600 overflow-hidden">
                    <div class="h-full w-full md:grid md:grid-cols-[400px_1fr]">
                        
                        <div>
                            <div class="h-full border-r border-gray-300 text-gray-700 py-4 md:py-12">
                                <div class="flex justify-between items-center px-4 md:px-8">
                                    <div class="text-[25px] md:text-[32px] font-medium ">
                                        Update feed
                                        <span class="font-normal">
                                            (Inbox)
                                        </span>
                                    </div>
                                    <v-btn class="block md:hidden" icon="close" size="medium" @click="closeInfo"></v-btn> 
                                </div>
                                <div class="mt-2 md:mt-8 px-4">   
                                    <div class="inline-block rounded-xl md:rounded-2xl bg-primary-100 px-2 md:px-5 p-3 mb-4 cursor-pointer hover:bg-primary-300 duration-300 ease hover:drop-shadow hover:shadow-lg active:translate-y-1 " @click="handleInputDialog">
                                        <div class="flex justify-start items-center gap-1 md:gap-2">
                                            <mdicon name="pencil-outline"></mdicon>
                                            <div>
                                                Compose
                                            </div>
                                        </div>
                                    </div>                                      
                                    <div>
                                        <v-select :options="tabs" v-model="selected_tab" class="block md:hidden"></v-select>
                                    </div>                           
                                    <div v-for="(t,i) in tabs" :key="i" @click="updateTab(t.value)" class="cursor-pointer hidden md:block hover:bg-primary-100 px-4 py-3 mb-[2px] duration-300 ease-in-out rounded" :class="t.value == selected_tab ? 'bg-primary-100 text-primary-500' : ''">
                                        <div class="flex justify-start items-center gap-2">
                                            <div>
                                                <mdicon :name="t.icon" size="25"  :class="t.value == selected_tab ? 'text-primary-500' :'text-gray-500'"></mdicon>
                                            </div>
                                            <div class="text-[14px]">
                                                {{ t.label }}
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="bg-base">
                            <nav class="w-full border-b border-gray-300 justify-end hidden md:flex items-center bg-white px-4 h-[50px]">
                                <v-btn icon="close" size="small" @click="closeInfo"></v-btn>
                            </nav>
                            <div>
                                <slotVue v-if="selected_tab == 1">
                                    <inboxVue></inboxVue>
                                </slotVue>
                                <slotVue v-if="selected_tab == 2">
                                    <outboxVue></outboxVue>
                                </slotVue>
                                <slotVue v-if="selected_tab == 3">
                                    <mentionVue></mentionVue>
                                </slotVue>
                                <slotVue v-if="selected_tab == 4">
                                    <bookmarkedVue></bookmarkedVue>
                                </slotVue>
                                <slotVue v-if="selected_tab == 5">
                                    <deletedVue></deletedVue>
                                </slotVue>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <messageInput v-model="inputdialog"></messageInput>
    </div>
</template>

<script>

import bookmarkedVue from "./bookmarked.vue";
import mentionVue from './mention.vue'
import outboxVue from './outbox.vue';
import messageInput from './messageInput.vue';
import inboxVue from './inbox.vue'
import slotVue from './slot.vue'
import logo from '@/assets/logo-kecil.svg'
import deletedVue from './deleted.vue';
export default {
    name: 'ErpUserMessage',

    props: {
        modelValue: Boolean, // Menggunakan modelValue sebagai nama properti untuk v-model
    },

    mounted() {
       
    },

    data() {
        return {
            selected_tab : 1 ,
            logo: logo,
            inputdialog : false,
            tabs : [
                {
                    label : 'Assigned to me',
                    value : 1,
                    icon : 'email-outline'
                },
                {
                    label : 'Outbox',
                    value : 2,
                    icon : 'email-arrow-right-outline'
                },
                {
                    label : 'I was mentioned',
                    value : 3,
                    icon : 'at'
                },
                {
                    label : 'Bookmarked',
                    value : 4,
                    icon : 'tag-outline'
                },
                {
                    label : 'Trash',
                    value : 5,
                    icon : 'trash-can-outline'
                }
            ],
        };
    },

    created () {
       
    },

    methods: {        
        handleInputDialog(){
            if(this.inputdialog) {
                this.inputdialog = false;
            } else {
                this.inputdialog = true;
            }
        },
        updateTab(item){
            this.selected_tab = item;
        },
        closeInfo() {
            this.$emit('update:modelValue', false);
        },
    },

    computed : {
        isOpen: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },

    components : {
        slotVue,
        inboxVue,
        messageInput,
        outboxVue,
        mentionVue,
        bookmarkedVue,
        deletedVue
    }
};
</script>

<style lang="scss" scoped>

.nested-enter-active .inner,
.nested-leave-active .inner { 
  transition: all 0.3s ease;
  transform-origin: top;
}
/* delay enter of nested element */

.nested-enter-from .inner,
.nested-leave-to .inner {
  opacity: 0.01;
  scale: 0;
}

.nested-enter-active,
.nested-leave-active { 
  transition: all 0.3s ease;
}
/* delay enter of nested element */

.nested-enter-from,
.nested-leave-to {
  opacity: 0.0;
}




</style>