<template>
    <div>
        <div class="w-full">
            <div class="text-lg w-full flex gap-2 text-gray-800 mb-2">
                <div>Education Data</div>
                <div class="flex justify-center items-center">
                    <mdicon name="school" class="text-gray-700" size="20"></mdicon>
                </div>               
            </div>

            <div class="table-auto w-full text-gray-800 mb-6">
                <div class="border-b border-gray-300 w-full grid grid-cols-[repeat(5,1fr)] text-gray-700">
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Start Date</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">End Date</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">School/University Name</div>
                </div>
                <div class="border-b border-gray-300 capitalize w-full grid grid-cols-[repeat(5,1fr)] text-gray-700" v-for="(edu, index) in education" :key="index">
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{edu.start_date}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{edu.end_date}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{edu.name}}</div>
                </div>
            </div>

            <div class="text-lg w-full flex gap-2 text-gray-800 mb-2">
                <div>Experience Data</div>
                <div class="flex justify-center items-center">
                    <mdicon name="certificate-outline" class="text-gray-700" size="20"></mdicon>
                </div>               
            </div>

            <div class="table-auto w-full text-gray-800 mb-6">
                <div class="border-b border-gray-300 w-full grid grid-cols-[repeat(4,1fr)] text-gray-700">
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Company name</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Job Position</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">Start Date</div>
                    <div class="w-full text-start py-3 text-[14px] tracking-wider whitespace-nowrap">End Date</div>
                </div>
                <div class="border-b border-gray-300 capitalize w-full grid grid-cols-[repeat(4,1fr)] text-gray-700" v-for="(exp, index) in experience" :key="index">
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{exp.company_name}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{exp.job_position}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{exp.start_date}}</div>
                    <div class="w-full text-start py-3 font-light text-[14px] tracking-wider">{{exp.end_date}}</div>                    
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmaErpEducation',
    props: ['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed:{
        education(){
            return this.data.education
        },
        experience(){
            return this.data.experience
        }
    }
};
</script>

<style lang="scss" scoped>

</style>