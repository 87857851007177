<template>
     <div class="relative">
            <label class="block text-gray-700 text-sm font-medium mb-2" v-if="label">{{ label }} <span class="text-danger-700 text-[14px]" v-if="required">*</span></label>
            <div class="flex items-center"> 
            <div class="mr-2" v-if="prepend" @click="$emit('click:prepend')">
                <mdicon :name="prepend" size="21" :class="[IconClasses, {'cursor-pointer' : clickPrepend }]"></mdicon>
            </div>    
            <textarea
                :cols="cols"
                :rows="rows"
                :required="required"
                class="border border-gray-400 text-[14px] rounded w-full py-2 px-3 text-gray-700 leading-normal focus:outline-none focus:border-primary-500"
                :type="type"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                @focus="focused = true"
                @blur="focused = false"
                v-bind="$attrs"
                :disabled="disabled"
            ></textarea>
            <div class="ml-2" v-if="append" @click="$emit('click:append')">
                <mdicon :name="append" size="21" :class="[IconClasses, {'cursor-pointer' : clickAppend }]"></mdicon>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        modelValue: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        required : {
            type: Boolean,
            default: false
        },
        disabled : {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 5
        },
        cols: {
            type: Number,
            default: 10
        },
        variant : {
            type: String,
            default: 'default'
        }
    },
    data() {
      return {
      }
    },
    computed: {
       
    },
   
  }
  </script>
  