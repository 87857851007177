<template>
  <div :class="classes" @click="onClick">
    <div class="flex items-center justify-center gap-[3px]">
      <mdicon v-if="prepend" :name="prepend" :size="iconSize"></mdicon>
        <slot></slot>
      <mdicon v-if="append" :name="append" :size="iconSize"></mdicon>
      <mdicon v-if="closable" name="close-circle" :size="iconSize" class="cursor-pointer" @click="$emit('close')"></mdicon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-chip',
  props: {
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'primary', 'secondary', 'danger', 'warning'].includes(value)
    },
    size: {
      type: String,
      default: 'normal',
      validator: value => ['small', 'normal', 'large','extra-large'].includes(value)
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    prepend: {
      type: [String, Boolean],
      default: false
    },
    append: {
      type: [String, Boolean],
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconSize(){

      let iconSize = ''

      switch (this.size) {
        case 'extra-small':
        iconSize = 16
          break
        case 'small':
        iconSize = 17
          break
        case 'normal':
        iconSize = 18
          break
        case 'large':
        iconSize = 19
          break
        case 'extra-large':
        iconSize = 22
          break
        default:
        iconSize = 18
          break
      }
      
      return [
        iconSize,
      ]

    },
    classes() {
      let bgClass = ''
      switch (this.type) {
        case 'primary':
          bgClass = 'bg-primary-100 hover:bg-primary-200'
          break
        case 'secondary':
          bgClass = 'bg-secondary-100 hover:bg-secondary-200'
          break
        case 'danger':
          bgClass = 'bg-danger-100 hover:bg-danger-300'
          break
        case 'warning':
          bgClass = 'bg-warning-100 hover:bg-warning-200'
          break
        default:
          bgClass = 'bg-gray-200 hover:bg-gray-200'
          break
      }
      let textClass = ''
      switch (this.type) {
        case 'primary':
        textClass = 'text-primary-700'
          break
        case 'secondary':
        textClass = 'text-secondary-600'
          break
        case 'danger':
        textClass = 'text-danger-600'
          break
        case 'warning':
        textClass = 'text-gray-600'
          break
        default:
        textClass = 'text-gray-600'
          break
      }

      let textSize = ''

      switch (this.size) {
        case 'extra-small':
        textSize = 'text-[10px]'
          break
        case 'small':
        textSize = 'text-[11px] font-medium'
          break
        case 'normal':
        textSize = 'text-[14px] font-medium'
          break
        case 'large':
        textSize = 'text-[16px] font-medium'
          break
        case 'extra-large':
        textSize = 'text-[18px] font-medium'
          break
        default:
        textSize = 'text-[14px] font-medium'
          break
      }
      
      return [
        bgClass,
        textClass,
        textSize,
        'rounded-full px-[12px] py-[3px] inline-block text-center transition-all ease-in-out duration-300',
        {
          'hover:bg-gray-700': this.type !== 'default',
          'hover:bg-gray-200': this.type === 'default'
        }
      ]
    }
  }
}
</script>

<style scoped>
/* Optional CSS styles for the component */
</style>
