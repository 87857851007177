import store from "@/store"
import SecureLS from "secure-ls" 
import moment from 'moment'
import * as XLSX from 'xlsx';
const  ls = new SecureLS({isCompression: false})
export default{
    data(){
        return {
            moment: moment
        }
    },

    methods: {      
      generateUniqueId() {
          const timestamp = new Date().getTime().toString(16); // Mendapatkan timestamp dalam format hexadecimal
          const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Karakter yang akan digunakan
          const randomLength = Math.floor(Math.random() * 15) + 18; // Panjang karakter acak antara 18-32
          let uniqueId = timestamp;
          
          // Menambahkan karakter acak sepanjang randomLength
          for (let i = 0; i < randomLength; i++) {
              uniqueId += randomChars[Math.floor(Math.random() * randomChars.length)];
          }
          
          return uniqueId;
      },

      calculateDateRange(value1, value2, formatted = 'D MMM YYYY') {
        const startDate = moment(value1);
        const endDate = moment(value2);
        const currentYear = moment().year();
      
        if (startDate.isSame(endDate, 'day')) {
          return startDate.format(formatted);
        }
      
        if (startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year')) {
          return `${startDate.format('D')}-${endDate.format('D MMM YYYY')}`;
        }
      
        if (startDate.year() === currentYear && endDate.year() === currentYear) {
          return `${startDate.format('D MMM')}-${endDate.format('D MMM YYYY')}`;
        }
      
        return `${startDate.format(formatted)}-${endDate.format(formatted)}`;
      },
      

      formattedDate(value1, formatted) {
        const startDate = moment(value1);      
        return startDate.format(formatted || 'D MMM YYYY');
      },

        checkpermited(require ,e,key){   
          if(!require){
            return true
          } else {
            let privilege = store.state.privilege

            for(let i = 0 ; i < privilege.length ; i++){                
                
                 if(e == privilege[i].module){
                    const permissions = privilege[i].permission    
                    
                    for (let [label, value] of Object.entries(permissions)) {
                        if(label == key && value == true){
                            return true
                        }
                     }          
                 }
            }
            return false
          }
        },
        checkrole() {
          if (localStorage.length > 0) {
            // Retrieve the erp-session data from local storage
            const session = ls.get('erp-session');
        
            if (session && session.data && session.data.role) {
              // Parse the role data from the session object
              const roleData = JSON.parse(session.data.role);
        
              // Extract the privilege property from the role data and parse it as an array
              const role = JSON.parse(roleData.privilege);
        
              // Update the role in the store
              store.commit('updaterole', role);
            }
          }
        },
        
        checktoken() {
          if (localStorage.length > 0) {
            let session = ls.get('erp-session');
            if (session && session.data && session.data.token) {
              return session.data.token;
            }
          }
          return false;
        },

        checkuser() {
          if (localStorage.length > 0) {
            // Retrieve the erp-session data from local storage
            const session = ls.get('erp-session');
        
            if (session && session.data && session.data.user) {
              // Parse the user data from the session object
              return JSON.parse(session.data.user);
            }
          }
        
          // If there is no erp-session or user data, return false
          return false;
        },

      exportToExcel(data, columns, filename) {
        const selectedData = data.map(item => {
          const selected = {};
          columns.forEach(column => {
            let key, header, type;
            if (typeof column === 'string') {
              key = column;
              header = column;
              type = 'text';
            } else {
              key = column.key;
              header = column.header;
              type = column.type || 'text';
            }
      
            if (key.includes('.')) {
              const keys = key.split('.');
              let value = item;
              for (const prop of keys) {
                value = value[prop];
                if (!value) break;
              }
              selected[header] = value || '';
            } else {
              selected[header] = item[key];
            }
      
            if (type === 'date') {
              const dateValue = selected[header];
              if (dateValue) {
                const dateObj = new Date(dateValue);
                if (!isNaN(dateObj.getTime())) {
                  selected[header] = {
                    t: 'd',
                    v: dateObj.toISOString().substring(0, 10)
                  };
                }
              }
            } else if (type === 'accounting') {
              const value = selected[header];
              if (value) {
                selected[header] = {
                  t: 'n',
                  z: '#,##0.00;',
                  v: value
                };
              }
            } else if (type === 'currency') {
              const value = selected[header];
              if (value) {
                selected[header] = {
                  t: 'n',
                  z: '"Rp "#,##0.00',
                  v: value
                };
              }
            } else if (type === 'time') {
              const value = selected[header];
              if (value) {
                const [hour, minute, second] = value.split(':');
                selected[header] = {
                  t: 'n',
                  z: 'hh:mm:ss',
                  v: new Date(1970, 0, 1, hour, minute, second)
                };
              }
            }
          });
          return selected;
        });
      
        const worksheet = XLSX.utils.json_to_sheet(selectedData, {
          header: columns.map(column => typeof column === 'string' ? column : column.header)
        });
      
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      },
    

      getInitials(name) {
        if (!name || typeof name !== 'string') {
            return '';  // Return empty string or some default initials
        }
        let names = name.split(" ");
        let initials = "";
        let numWords = 2;

        if (names.length < numWords) {
            initials = name[0] + name[1];
        } else {
            for (var i = 0; i < numWords; i++) {
                initials += names[i][0];
            }
        }
        return initials;
    },

    },

    mounted() {
       this.checkrole()
       this.checkpermited()
    },
    created(){
      
    },
    computed:{
    token(){
			return this.checktoken()
		},
    loggedInUser(){
			return this.checkuser()
		},
    },
    watch:{
      token:function(newValue){
        return newValue
      },
      loggedInUser:function(newValue){
        return newValue
      }
        
    }
}