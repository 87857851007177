<template>
    <div class="fixed w-full inset-0 h-screen z-10">
        <div class="fixed z-10 w-full h-screen inset-0 bg-black opacity-50"></div>
        <div class="flex justify-center items-center h-full">
            <div class="p-4 gap-4 rounded-sm max-w-[35%] flex z-20" :class="computedClasses">
                <div v-if="showIcon && computedIcon">
                    <mdicon :name="icon ?  icon : computedIcon" size="30"></mdicon>
                </div>
                <div class="w-full">
                    <div class="text-2xl font-medium capitalize mb-1" v-if="title">{{ title }}</div>
                    <div class="text-base py-2">{{ message }}</div>
                </div>
                <div>
                    <mdicon name="close" size="18" class="cursor-pointer" @click="$emit('close',false)"></mdicon>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    name: 'PadmaErpVAlert',
    props: {
        type : {
            Type: String,
            default : 'default'
        },
        title : {
            Type: [String, Boolean],
            default : false
        },
        message : {
            Type: String,
            default : ''
        },
        showIcon : {
            Type: Boolean,
            default : true
        },
        icon : {
            Type: [String, Boolean],
            default : false
        },
        
    },

    data() {
        return {
            
        };
    },

    mounted() {
        console.log('alert mounted')
    },

    methods: {
        
    },

    computed : {
        computedClasses(){
            return [

                'shadow-md drop-shadow-md',
                {'bg-white' : this.type === 'default'},
                {'bg-primary-600 text-white' : this.type === 'success'},
                {'bg-secondary-600 text-white' : this.type === 'info'},
                {'bg-danger-600 text-white' : this.type === 'error'},
                {'bg-warning-600 text-white' : this.type === 'warning'}
            ]
        },
        computedIcon(){
           if(this.type === 'success'){
             return 'check-circle'
           }

           if(this.type === 'info'){
             return 'information'
           }

           if(this.type === 'error'){
             return 'close-circle'
           }

           if(this.type === 'warning'){
             return 'alert-circle'
           }

           if(this.icon) {
                return this.icon
           } else {
                return false
           }
        }
    }
};
</script>

<style lang="scss" scoped>

</style>