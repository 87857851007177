<template>
    <transition name="fade" mode="out-in">
        <div  v-if="isOpen" class="fixed inset-0 z-[998] flex justify-center items-center overflow-y-auto h-screen px-10 pb-20">
            <v-loading v-if="isloading"></v-loading>
          <div class="fixed inset-0 bg-black opacity-50"></div>
            <div class="relative mt-10 max-h-screen p-10">
                <div :class="transitionClass">
                    <div class="rounded overflow-hidden w-[80vw]">
                        <div class="w-full bg-gray-100 h-8 p-4 flex justify-between items-center py-6">
                            <div class="text-sm tracking-wide">
                                New Message
                            </div>
                            <v-btn icon="close" size="small" @click="emitUpdateDialog"></v-btn>
                        </div>
                        <div class="bg-white p-4">
                            <div class="border-b w-full flex flex-wrap flex-grow-0 justify-start gap-2 items-center mb-2 py-1">
                                <div class="justify-start items-center gap-1 flex flex-wrap flex-grow-0" v-if="receiver_list.length">
                                    <div v-for="(rl,index) in receiver_list" :key="index" class="flex justify-start items-center gap-1 border border-gray-300 rounded-full px-2 py-[4px]">
                                        <div class="w-5 h-5 overflow-hidden rounded-full">
                                            <img v-if="rl.photo_url != null" :src="rl.photo_url" alt="" class="w-full h-full object-cover object-center">
                                            <div v-if="rl.photo_url == null" class="w-full h-full bg-primary-500 text-white flex justify-center items-center text-[8px] tracking-widest font-bold">
                                                {{ getInitials(rl.employee_name) }}
                                            </div>
                                        </div>  
                                        <div class="text-[12px]">{{ rl.employee_name }}</div>
                                        <div><mdicon name="close" size="12" class="cursor-pointer text-gray-600" @click="removeReciever(index)"></mdicon></div>
                                    </div>
                                </div>
                                <div class="w-full relative">
                                    <input class="focus:outline-none text-[14px] w-full" v-model="receiverText"  v-debounce:500ms="searchReceiver"  placeholder="recipient">
                                    <div class="absolute z-10 bg-white rounded-sm shadow py-2 w-[350px]  max-h-[200px] overflow-y-auto" v-if="isshowReceivers && receiver_suggestions.length">
                                        <div v-for="(sg,indexSugest) in receiver_suggestions" :key="indexSugest" class="w-full cursor-pointer hover:bg-gray-200 duration-300 ease px-5 py-2" @click="SelectReciever(sg)">
                                            <v-list-tile>
                                                <template #leading>
                                                    <div class="w-8 h-8 overflow-hidden rounded-full">
                                                        <img v-if="sg.photo_url != null" :src="sg.photo_url" alt="" class="w-full h-full object-cover object-center">
                                                        <div v-if="sg.photo_url == null" class="w-full h-full bg-primary-500 text-white flex justify-center items-center text-xs tracking-widest font-bold">
                                                            {{ getInitials(sg.employee_name) }}
                                                        </div>
                                                    </div>  
                                                </template>
                                                <template #title>
                                                    <div class="text-[12px]">
                                                        {{ sg.employee_name }}
                                                    </div>
                                                </template>
                                                <template #subtitle>
                                                    <div class="text-[11px]">
                                                        {{ sg.username }}
                                                    </div>
                                                </template>
                                            </v-list-tile> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="relative py-1 border-b w-full">
                                <input class="focus:outline-none w-full text-[14px]" placeholder="subject" v-model="subjectText">
                            </div>
                        </div>
                        <div class="h-[300px] bg-white px-4">
                            <textarea name="" id="" ref="textArea" class="w-full h-full text-[14px] focus:outline-none" placeholder="enter message"  v-debounce:500ms="handleInput" @keydown="handleKeyDown"></textarea>
                            <div v-if="isshowSuggestions" ref="suggestionsList" class="suggestions rounded max-h-[200px] overflow-y-auto">
                                <div v-for="(mention,indexMention) in mentions" :key="indexMention" @click="selectMention(mention.username)" class="w-full cursor-pointer hover:bg-gray-200 duration-300 ease px-4 py-1">
                                    <v-list-tile>
                                        <template #leading>
                                            <div class="w-7 h-7 overflow-hidden rounded-full">
                                                <img v-if="mention.photo_url != null" :src="mention.photo_url" alt="" class="w-full h-full object-cover object-center">
                                                <div v-if="mention.photo_url == null" class="w-full h-full bg-primary-500 text-white flex justify-center items-center text-xs tracking-widest font-bold">
                                                    {{ getInitials(mention.employee_name) }}
                                                </div>
                                            </div>  
                                        </template>
                                        <template #title>
                                            <div class="text-[12px]">
                                                {{ mention.employee_name }}
                                            </div>
                                        </template>
                                        <template #subtitle>
                                            <div class="text-[11px]">
                                                {{ mention.username }}
                                            </div>
                                        </template>
                                    </v-list-tile> 
                                </div>
                            </div>                     
                        </div>
                        <div class="flex justify-between items-center bg-white p-4">
                            <v-btn icon="trash-can-outline" size="small"></v-btn>
                            <v-btn type="primary" @click="send">Send</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'PadmaErpMessageInput',
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
    },
    data() {
        return {
            isloading : false,
            receiverText : '',
            receiver_suggestions : [],
            
            receiver_list : [],
            inputText: "",
            subjectText: "",
            lastWord : null,
            isshowSuggestions: false,
            isshowReceivers : false,
            mentions: [
            ] 
        };
    },

    mounted() {
        
    },

    methods: {
        searchReceiver(){

            if(this.receiverText != ''){
                this.receiver_suggestions = [];    
                let formData = new FormData();
                formData.append('receiver_text', this.receiverText);
    
                const headers = {
                    'Content-Type' : 'application/json',
                    'Access-Control-Allow-Origin' : '*',
                    'Accept' : 'application/json'
                }
                this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post('api/message/select_receiver',formData,headers).then((response)=>{
                    let resData = response.data;
                    resData.forEach(element => {
                        let obj = {
                            username : element.username,
                            employee_name : element.employee.employee_name,
                            email : element.employee.email,
                            photo_url : element.employee.photo_url
                        }
    
                        this.receiver_suggestions.push(obj);
                    });
                    
                    this.$nextTick(()=>{
                        this.isshowReceivers = true;
                    })
                }).catch((error)=>{
                   
                })
            } else {
                this.receiver_suggestions = [];
                this.$nextTick(()=>{
                    this.isshowReceivers = false;
                })
            }
        },
        searchMention(text){
            this.mentions = [];    
            let formData = new FormData();
            formData.append('mention_text', text);

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json'
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/message/select_mention',formData,headers).then((response)=>{
                let resData = response.data;
                resData.forEach(element => {
                    let obj = {
                        username : element.username,
                        employee_name : element.employee.employee_name,
                        email : element.employee.email,
                        photo_url : element.employee.photo_url
                    }

                    this.mentions.push(obj);
                });
            })
        },
        selectMention(mention){
            const textarea = this.$refs.textArea;
            const cursorPos = textarea.selectionStart;

            // Regular expression untuk menemukan kata terakhir yang diawali dengan "@"
            const regex = /(^|\s)@(\w*)$/;
            let text = textarea.value;

            // Temukan kata terakhir yang diawali dengan "@"
            const match = text.match(regex);

            if (match) {
            // Ganti kata terakhir dengan `mention`, termasuk karakter "@"
                text = text.replace(regex, `$1@${mention}`);
                
                // Update nilai textarea
                textarea.value = text;

                // Atur posisi kursor setelah mention
                textarea.setSelectionRange(cursorPos, cursorPos + mention.length);

                // Tutup suggestions setelah memilih mention
                this.isshowSuggestions = false;
            }
        },
        handleInput(event) {
            this.mentions = [];
            this.lastWord = null;
            const textarea = this.$refs.textArea;
            const cursorPos = textarea.selectionStart;

            const textBeforeCursor = textarea.value.substring(0, cursorPos);
            const regex = /(?:^|\s)@(\w*)$/;
            const match = textBeforeCursor.match(regex);
            
            if (match) {
                this.lastWord = match[1]; // Simpan kata yang diawali dengan "@" terakhir
                this.searchMention(this.lastWord)

                this.isshowSuggestions = true;
                
                const cursorCoordinates = this.getCursorCoordinates(textarea, cursorPos);
                const { top, left } = cursorCoordinates;

                this.$nextTick(() => {
                this.$refs.suggestionsList.style.top = top + 'px';
                this.$refs.suggestionsList.style.left = left + 'px';
                });
                
            } else {
                // Jika kata diawali dengan "@" dihapus sepenuhnya, tutup suggestions
                const newMatch = textarea.value.match(regex);
                if (!newMatch || newMatch[1] !== this.lastWord) {
                    this.isshowSuggestions = false;
                }
            }
        },
        getCursorCoordinates(textarea, cursorPos) {
            const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);

            // Calculate the number of lines up to the cursor position
            const linesBeforeCursor = textarea.value.substr(0, cursorPos).split('\n').length - 1;
            
            // Calculate the width of the text on the current line
            const dummyDiv = document.createElement('div');
            dummyDiv.style.position = 'absolute';
            dummyDiv.style.whiteSpace = 'pre-wrap';
            dummyDiv.innerText = textarea.value.split('\n')[linesBeforeCursor];
            
            document.body.appendChild(dummyDiv);
            const textBoundingRect = dummyDiv.getBoundingClientRect();
            document.body.removeChild(dummyDiv);

            const { left } = textarea.getBoundingClientRect();
            const cursorX = left + textBoundingRect.width - 150;
            const cursorY = linesBeforeCursor * lineHeight + 220;

            return {
                top: cursorY,
                left: cursorX
            };
        },
        emitUpdateDialog() {
            this.$emit('update:modelValue', !this.modelValue);
        },
        removeReciever(item){
            let obj = this.receiver_list[item];
            this.receiver_suggestions.push(obj);
            this.receiver_list.splice(item,1);
        },
        SelectReciever(item){
            const isItemExists = this.receiver_list.some(receiver => receiver.username === item.username);
    
            // Jika item belum ada, tambahkan item ke receiver_list
            if (!isItemExists) {
                this.receiver_list.push(item);
            }
            
            // Membersihkan data lainnya
            this.receiver_suggestions = [];
            this.receiverText = '';
            this.isshowReceivers = false;
            
        },

        send(){

            const textAreaValue = this.$refs.textArea;
            let content = textAreaValue.value;

            this.isloading = true;
            let formData = new FormData();
            formData.append('sender_id', this.loggedInUser.username);
            formData.append('content', content);
            formData.append('subject', this.subjectText);
            formData.append('receivers', JSON.stringify(this.receiver_list));

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json'
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/message/store',formData,headers).then((response)=>{
                this.receiver_list = [];
                this.inputText= "";
                this.subjectText= "";
                let rs = response.data.message;
                this.$notify({
                    type: "success",
                    title: 'Success',
                    text: rs,
                }); 
                this.emitUpdateDialog();
            }).catch((error)=>{
            }).finally(()=>{
                this.isloading = false;
            });
        },

        
    },
    computed: {
      isOpen: {
        get() {
          return this.modelValue;
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue);
        },
      },
      transitionClass() {
        if (this.isOpen) {
            return 'bounce-enter-active';
          } else {
            return 'bounce-leave-active';
          }          
      },
    },

    watch: {
      modelValue(newValue) {
        this.isOpen = newValue;
      },
    },
};
</script>

<style lang="scss" scoped="scoped">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  /* Define the bounce animation */
  @keyframes bounce {
    0% {
      transform: scale(0);
      opacity: 0;
    }
  
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the bounce animation to the slot content */
  .bounce-enter-active {
    animation: bounce 0.7s;
  }
  
  @keyframes bounce-out {
    0% {
      transform: scale(1);
      opacity: 1;
    }
  
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
  
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  
  /* Apply the bounce-out animation to the slot content when closing */
  .bounce-leave-active {
    animation: bounce-out 0.5s;
  }


  .suggestions {
        position: absolute;
        z-index: 999;
        background-color: white;
        border: 1px solid #ccc;
        padding: 8px 0px;
        min-width: 200px;

    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
    padding: 8px;
        cursor: pointer;
    }
  </style>
  