<template>
    <div class="">
        <div class="w-full flex flex-col gap-3 text-gray-800 px-5" v-if="data != null">
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="badge-account-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Employee ID:<span class="ml-2">{{data.employee_id}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="barcode" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Barcode:<span class="ml-2">{{data.barcode == 'null' ? '-' : data.barcode}}</span></div>
            </div>            
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calendar-account-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Join Date:<span class="ml-2">{{moment(data.join_date).locale('id').format('LL')}}</span></div>
            </div>  
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="file-document-edit" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Employment Status:<span class="ml-2">{{data.employment_statuses.employment_status}}</span></div>
            </div>               
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calendar-account" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Status Date:<span class="ml-2">{{moment(data.status_date).locale('id').format('LL')}}</span></div>
            </div>  
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="certificate-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Grade Status:<span class="ml-2">{{data.grades.grade}}</span></div>
            </div>                  
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="calendar-clock" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Grade Date:<span class="ml-2">{{moment(data.grade_date).locale('id').format('LL')}}</span></div>
            </div>  
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="office-building-marker" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Branch:<span class="ml-2">{{data.branches.branch}}</span></div>
            </div>                   
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="sitemap-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Department:<span class="ml-2">{{data.departments.department}}</span></div>
            </div>                   
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="account-tie" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Job Level:<span class="ml-2">{{data.job_levels.job_level}}</span></div>
            </div>                   
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="account-tag-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 font-light text-[14px] tracking-wider">Job Position:<span class="ml-2">{{data.job_positions.job_position}}</span></div>
            </div>                   
           
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
export default {
    name: 'PadmaErpEmployment',
    props: ['data'],
    data() {
        return {
            moment : moment
        };
    },

    mounted() {
       
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>