import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdminLayout from '@/layout/AdminLayout.vue'
import LoginLayout from '@/layout/LoginLayout.vue'
import layout from '@/layout/slot/index.vue'

import SecureLS from "secure-ls" 
const  ls = new SecureLS({isCompression: false})
import moment from 'moment'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
        group : 'home',
        layout: AdminLayout,
        requiresAuth: true,
        pagename: 'dashboard',
        icon: 'view-dashboard-outline'
    } 
  },
  {
    path: '/employee',
    children: [
      {
        path: '',
        name: 'employee',
        component: () => import(/* webpackChunkName: "employee" */ '../views/EmployeeView.vue'),
        meta: {
            group : 'employee',
            permissions : 'Read',
            module : 'Employee',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Employee',
            icon: 'account-group-outline'
        } 
      },
      {
        path: 'employee-list',
        name: 'employee-list',
        component: () => import(/* webpackChunkName: "employee-list" */ '../views/EmployeeListView.vue'),
        meta: {
            group : 'employee',
            permissions : 'Read',
            module : 'Employee',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Employee',
            icon: 'account-group'
        } 
      },
      {
        path: 'resigned-employee',
        name: 'resigned-employee',
        component: () => import(/* webpackChunkName: "resigned-employee" */ '../views/ResignedEmployeeView.vue'),
        meta: {
            group : 'employee',
            permissions : 'Read',
            module : 'Employee',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Resigned Employee',
            icon: 'account-multiple-remove-outline'
        } 
      },
      {
        path: ':id/detail',
        name: 'detail-employee',
        props: true,
        component: () => import(/* webpackChunkName: "detail-employee" */ '../views/DetailEmployee.vue'),
        meta: {
            group : 'employee',
            permissions : 'Read',
            module : 'Employee',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Detail Employee',
            icon: 'account-outline'
        } 
      }
    ]    
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
        layout: LoginLayout,
    } 
  },
  {
    path: '/email-verification/email=:email&&token=:token',
    name: 'EmailVerification',
    props: true,
    component: () => import(/* webpackChunkName: "EmailVerification" */ '../views/EmailVerificationView.vue'),
    meta: {
        layout: LoginLayout,
    } 
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/forbiddenView.vue'),
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/notFoundView.vue'),
  },
  {
    path: '/company-setup',
    children: [
      {
        path : 'tab=:tabs?',
        props : true,
        component: () => import(/* webpackChunkName: "branch" */ '../views/CompanySetupView.vue'),
        meta: {          
            group : 'company-setup',
            permissions : 'Create',
            name : 'company-setup',
            module : 'Company-Setup',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Company Setup',
            icon: 'cogs'
        } 
      }      
    ]    
  },
  {
    path: '/user-management',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
    meta: {
        group : 'user-management',
        permissions : 'Create',
        module : 'User-Management',
        layout: AdminLayout,
        requiresAuth: true,
        requiresPermission: true,
        pagename: 'User Management',
        icon: 'account-group-outline'
    } 
  },
  {
    path : '/role-management',
    component: layout,
    children : [
      {
        path : '',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '../views/RoleView.vue'),
        meta: {
            group : 'role-management',
            permissions : 'Create',
            module : 'Role-Management',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Role Management',
            icon: 'lock-outline'
        },
      },
      {
        path: ':id/privilege',
        props: true,
        name: 'privilege',
        component: () => import(/* webpackChunkName: "privilege" */ '../views/PrivilegeView.vue'),
        meta: {
            group : 'user-management',
            module : 'Role-Management',
            permissions : 'Create',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Privilege',
            icon: 'account-key-outline'
        } 
      }
    ]
  },
  {
    path: '/payroll-configuration',
    component: layout,
    children : [
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'payroll-configuration',
        component: () => import(/* webpackChunkName: "payroll-configuration" */ '../views/PayrollConfigurationView.vue'),
        meta: {
            group : 'payroll-configuration',
            permissions : 'Create',
            module : 'Payroll-Configuration',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Payroll Configuration',
            icon: 'file-cog-outline'
        },
      }
    ]
  },
  {
    path: '/parameter',
    component: layout,
    children : [
        {
          path: 'tab=:tabs?',
          name: 'parameter',
          props : true,
          component: () => import(/* webpackChunkName: "parameter" */ '../views/ParameterView.vue'),
          meta: {
              group : 'parameter',
              permissions : 'Create',
              module : 'Parameter',
              layout: AdminLayout,
              requiresAuth: true,
              requiresPermission: true,
              pagename: 'Parameter',
              icon: 'cog-outline'
          },
        },
    ]
  },
  {
    path: '/payroll',
    component: layout,
    children : [
        {
          path: '',
          name: 'payroll',
          component: () => import(/* webpackChunkName: "payroll" */ '../views/PayrollView.vue'),
          meta: {
              group : 'payroll',
              permissions : 'Create',
              module : 'Payroll',
              layout: AdminLayout,
              requiresAuth: true,
              requiresPermission: true,
              pagename: 'Payroll',
              icon: 'account-cash'
          },
        },
        {
          path: 'payroll-component',
          component: layout,
          children : [
            {
              path: '',
              name: 'payroll-component',
              component: () => import(/* webpackChunkName: "payroll-component" */ '../views/PayrollComponentView.vue'),
              meta: {
                  group : 'payroll',
                  permissions : 'Create',
                  module : 'Payroll',
                  layout: AdminLayout,
                  requiresAuth: true,
                  requiresPermission: true,
                  pagename: 'Payroll Component',
                  icon: 'account-cash'
              },
            }
          ]
          
        },
        {
          path: 'run-payroll',
          name: 'run-payroll',
          component: () => import(/* webpackChunkName: "run-payroll" */ '../views/RunPayrollView.vue'),
          meta: {
              group : 'payroll',
              permissions : 'Create',
              module : 'Payroll',
              requiresAuth: true,
              requiresPermission: true,
              pagename: 'Run Payroll',
              icon: 'run-fast'
          },
        },
        {
          path: 'tax-recalculate',
          name: 'tax-recalculate',
          component: () => import(/* webpackChunkName: "tax-recalculate" */ '../views/TaxRecalculateView.vue'),
          meta: {
              group : 'payroll',
              permissions : 'Create',
              module : 'Payroll',
              requiresAuth: true,
              layout: AdminLayout,
              requiresPermission: true,
              pagename: 'Tax Recalculate',
              icon: 'calculator'
          },
        },
    ]
  },
  {
    path: '/payroll-report',
    component: layout,
    children : [
        {
          path: 'tab=:tabs?',
          props: true,
          name: 'payroll-report',
          component: () => import(/* webpackChunkName: "report" */ '../views/PayrollReportView.vue'),
          meta: {
              group : 'payroll-report',
              permissions : 'Read',
              module : 'Payroll-Report',
              layout: AdminLayout,
              requiresAuth: true,
              requiresPermission: true,
              pagename: 'Payroll Report',
              icon: 'file-chart-outline'
          },
        },

        {
          path: 'detail/:id',
          props: true,
          name: 'detail-payroll-report',
          component: () => import(/* webpackChunkName: "detail-payroll-report" */ '../views/DetailPayrollReportView.vue'),
          meta: {
              group : 'payroll-report',
              permissions : 'Read',
              module : 'Payroll-Report',
              layout: AdminLayout,
              requiresAuth: true,
              requiresPermission: true,
              pagename: 'Payroll Report',
              icon: 'file-chart-outline'
          },
        },

        {
          path: 'tax-detail/:id',
          props: true,
          name: 'detail-payroll-tax',
          component: () => import(/* webpackChunkName: "detail-payroll-tax" */ '../views/DetailPayrollTaxView.vue'),
          meta: {
              group : 'payroll-report',
              permissions : 'Read',
              module : 'Payroll-Report',
              layout: AdminLayout,
              requiresAuth: true,
              requiresPermission: true,
              pagename: 'Payroll Report',
              icon: 'file-chart-outline'
          },
        },
    ]
  },
  {
    path: '/time-management',
    children: [
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'time-management',
        component: () => import(/* webpackChunkName: "time-management" */ '../views/TimeManagementView.vue'),
        meta: {
            group : 'time-management',
            permissions : 'Read',
            module : 'Time-Management',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Time Management',
            icon: 'clock-time-two-outline'
        } 
      }      
    ]    
  },
  {
    path: '/target-review-setting',
    children: [
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'target-and-review-setting',
        component: () => import(/* webpackChunkName: "target-and-review-setting" */ '../views/SettingGoalReviewView.vue'),
        meta: {
            group : 'target-and-review-setting',
            permissions : 'Read',
            module : 'Target-and-Review-Setting',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Target & Review Setting',
            icon: 'application-cog-outline',
            dynamicPermission: (to) => {
              return to.params.tabs === 'performance-report' ? 'Report' :
              'Read';
            },
        } 
      },
      {
        path: 'report-detail/id=:id/cycle=:cycle?',
        props : true,
        name: 'report-detail-performace-review-setting',
        component: () => import(/* webpackChunkName: "report-detail-performace-review-setting" */ '../views/DetailPerformanceReviewReport.vue'),
        meta: {
            group : 'target-and-review-setting',
            permissions : 'Report',
            module : 'Target-and-Review-Setting',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Performance Review Report',
            icon: 'file-chart-check-outline'
        } 
      },       
    ]    
  },
  {
    path: '/target-review',
    children: [
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'target-review',
        component: () => import(/* webpackChunkName: "target-review" */ '../views/GoalView.vue'),
        meta: {
            group : 'target-review',
            permissions : 'Read',
            module : 'Target-and-Review',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Target & Review',
            icon: 'bullseye-arrow'
        } 
      },
      {
        path: 'performance-review/id=:id',
        props : true,
        name: 'performance-review',
        component: () => import(/* webpackChunkName: "performance-review" */ '../views/FormReview.vue'),
        meta: {
            group : 'target-review',
            permissions : 'Read',
            module : 'Target-and-Review',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Performance Review',
            icon: 'bullseye-arrow'
        } 
      },
      {
        path: 'detail/id=:id/cycle=:cycle?',
        props : true,
        name: 'detail-performace-review',
        component: () => import(/* webpackChunkName: "detail-performace-review" */ '../views/DetailPerformanceReviewReport.vue'),
        meta: {
            group : 'target-review',
            permissions : 'Read',
            module : 'Target-and-Review',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Performance Review Report',
            icon: 'file-chart-check-outline'
        } 
      }, 
      {
        path: 'report-detail/id=:id/cycle=:cycle?',
        props : true,
        name: 'report-detail-performace-review',
        component: () => import(/* webpackChunkName: "report-detail-performace-review" */ '../views/DetailPerformanceReviewReport.vue'),
        meta: {
            group : 'target-review',
            permissions : 'Read',
            module : 'Target-and-Review',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Performance Review Report',
            icon: 'file-chart-check-outline'
        } 
      }, 
      {
        path: 'review-cycle/detail/id=:id',
        props : true,
        name: 'detail-cycle',
        component: () => import(/* webpackChunkName: "detail-cycle" */ '../views/ReviewCycleDetail.vue'),
        meta: {
            group : 'target-review-setting',
            permissions : 'Read',
            module : 'Target-and-Review-Setting',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Review Cycle Detail',
            icon: 'playlist-check'
        } 
      }
    ]    
  },

  


  {
    path: '/my-payroll',
    children: [
      {
        path: 'payroll-detail/:employee_id/:id',
        props : true,
        name: 'payroll-detail',
        component: () => import(/* webpackChunkName: "payroll-detail" */ '../components/my-payroll/detail-payroll.vue'),
        meta: {
            group : 'my-payroll',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'My Payroll',
            icon: 'file-account-outline',
        } 
      },
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'my-payroll',
        component: () => import(/* webpackChunkName: "my-payroll" */ '../views/MyPayrollView.vue'),
        meta: {
            group : 'my-payroll',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'My Payroll',
            icon: 'file-account-outline',
        } 
      },
    ]
  },
  {
    path: '/time-off',
    children: [
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'time-off',
        component: () => import(/* webpackChunkName: "time-off" */ '../views/timeOffView.vue'),
        meta: {
            group : 'time-off',
            permissions : 'Read',
            module : 'Time-Off',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'time-off',
            icon: 'calendar-clock',
            dynamicPermission: (to) => {
              return to.params.tabs === 'time-off-report' ? 'Report' :
              to.params.tabs === 'time-off-on-request' ? 'Request' :
              to.params.tabs === 'time-off-autorization' ? 'Authorization' :
              'Read';
            
            },
        } 
      },
    ]
  },
  {
    path: '/overtime',
    children: [
      {
        path: 'autorization=:id',
        props : true,
        name: 'overtime_authorization',
        component: () => import(/* webpackChunkName: "overtime_authorization" */ '@/components/overtime/component/overtimeOvertimeAuthorization.vue'),
        meta: {
            group : 'overtime',
            permissions : 'Supervisor',
            module : 'Overtime',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'overtime',
            icon: 'clock-plus-outline'
        } 
      },
      {
        path: 'detail=:id',
        props : true,
        name: 'detail-overtime',
        component: () => import(/* webpackChunkName: "detail-overtime" */ '@/components/overtime/detail-overtime.vue'),
        meta: {
            group : 'overtime',
            permissions : 'Read',
            module : 'Overtime',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'overtime',
            icon: 'clock-plus-outline'
        } 
      },
      {
        path: 'tab=:tabs?',
        props : true,
        name: 'overtime',
        component: () => import(/* webpackChunkName: "overtime" */ '../views/overtimeView.vue'),
        meta: {
            group : 'overtime',
            permissions : 'Read',
            module : 'Overtime',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'overtime',
            icon: 'clock-plus-outline',
            dynamicPermission: (to) => {
              return to.params.tabs === 'subordinate-overtime' ? 'Supervisor' :
              to.params.tabs === 'authorization-overtime' ? 'Supervisor' :
              to.params.tabs === 'overtime-report' ? 'Report' :
              'Read';
            },
        } 
      },
    ]
  },

  {
    path: '/inventory-management',
    children: [
      {
        path: 'manage_po/:id?',
        name: 'manage_po',
        props : true,
        component: () => import(/* webpackChunkName: "manage_po" */ '@/components/inventory/form_inventory_po.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Purchase Order'
        },
        children: [
           
        ]
      },
      {
        path: 'receive_po/:id',
        name: 'receive_po',
        props : true,
        component: () => import(/* webpackChunkName: "receive_po" */ '@/components/inventory/form_inventory_receive.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Receive Order'
        },
        children: [
           
        ]
      },
      {
        path: 'manage_return',
        name: 'manage_return',
        props : true,
        component: () => import(/* webpackChunkName: "manage_return" */ '@/components/inventory/form_inventory_return.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Receive Order'
        },
        children: [
           
        ]
      },
      {
        path: 'manage_out',
        name: 'manage_out',
        props : true,
        component: () => import(/* webpackChunkName: "manage_out" */ '@/components/inventory/form_inventory_out.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Receive Order'
        },
        children: [
           
        ]
      },
      {
        path: 'detail_po/:id',
        name: 'detail_po',
        props : true,
        component: () => import(/* webpackChunkName: "detail_po" */ '@/components/inventory/detail_inventory_po.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Purchase Order'
        },
        children: [
           
        ]
      },
      {
        path: 'detail_ro/:id',
        name: 'detail_ro',
        props : true,
        component: () => import(/* webpackChunkName: "detail_ro" */ '@/components/inventory/detail_inventory_ro.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Receive Order'
        },
        children: [
           
        ]
      },
      {
        path: 'detail_return/:id',
        name: 'detail_return',
        props : true,
        component: () => import(/* webpackChunkName: "detail_return" */ '@/components/inventory/detail_inventory_return.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Receive Order'
        },
        children: [
           
        ]
      },
      {
        path: 'detail_item_out/:id',
        name: 'detail_item_out',
        props : true,
        component: () => import(/* webpackChunkName: "detail_item_out" */ '@/components/inventory/detail_inventory_out.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Stock',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Receive Order'
        },
        children: [
           
        ]
      },
      {
        path: ':tabs',
        name: 'inventory',
        props : true,
        component: () => import(/* webpackChunkName: "inventory" */ '../views/InventoryView.vue'),
        meta: {
            group : 'inventory',
            permissions : 'Item',
            module : 'Inventory',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Inventory Management',
            dynamicPermission: (to) => {
              return to.params.tabs === 'inventory-stock' ? 'Item' :
              to.params.tabs === 'purchase-order' ? 'Stock' :
              to.params.tabs === 'receive-order' ? 'Stock' :
              to.params.tabs === 'return-item' ? 'Stock' :
              to.params.tabs === 'vendor' ? 'Vendor' :
              to.params.tabs === 'item' ? 'Stock' :
              'Item';
            },
        },
        children: [
           
        ]
      },
    ]
  },

  {
    path: '/activity',
    children: [
      {
        path: 'form_activity/:id?',
        name: 'form_activity',
        props : true,
        component: () => import(/* webpackChunkName: "form_activity" */ '../components/activity/formActivity.vue'),
        meta: {
            group : 'activity',
            permissions : 'Create',
            module : 'Activity',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Activity'
        },
        children: [
           
        ]
      },
      {
        path: ':tabs',
        name: 'activity',
        props : true,
        component: () => import(/* webpackChunkName: "activity" */ '../views/ActivityView.vue'),
        meta: {
            group : 'activity',
            permissions : 'Read',
            module : 'Activity',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Activity'
        },
        children: [
           
        ]
      },
    ]
  },

  {
    path: '/company-policies',
    children: [
      {
        path: ':tabs',
        name: 'company-policies',
        props : true,
        component: () => import(/* webpackChunkName: "company-policies" */ '../views/companyPoliciesView.vue'),
        meta: {
            group : 'company-policies',
            permissions : 'Read',
            module : 'Company-Policies',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Company-Policies',
            dynamicPermission: (to) => {
              return to.params.tabs === 'parameter' ? 'Parameter' :
              'Read';
            },
        },
        children: [
           
        ]
      },
    ]
  },

  {
    path: '/helpdesk-params',
    children: [
      {
        path: ':tabs',
        name: 'helpdesk-params',
        props : true,
        component: () => import(/* webpackChunkName: "helpdesk-params" */ '../views/HelpdeskParamsView.vue'),
        meta: {
            group : 'helpdesk-params',
            permissions : 'Read',
            module : 'Helpdesk-Params',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Helpdesk-Params',
        },
        children: [
           
        ]
      },
      {
        path: 'group-agent/id=:id',
        name: 'detail_group_agent',
        props : true,
        component: () => import(/* webpackChunkName: "detail_group_agent" */ '@/components/helpdesk-parameter/detailAgent.vue'),
        meta: {
            group : 'helpdesk-params',
            permissions : 'Update',
            module : 'Helpdesk-Params',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'helpdesk-Params',
        },
        children: [
           
        ]
      },
    ]
  },
  {
    path: '/helpdesk',
    children: [
      {
        path: 'open-ticket',
        name: 'openTicket',
        props : true,
        component: () => import(/* webpackChunkName: "openTicket" */ '@/components/helpdesk/openTicket.vue'),
        meta: {
            group : 'helpdesk',
            permissions : 'Request',
            module : 'Helpdesk',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Helpdesk',
        },
        children: [
           
        ]
      },
      {
        path: 'detail-ticket/id=:id',
        name: 'detailTicket',
        props : true,
        component: () => import(/* webpackChunkName: "detailTicket" */ '@/components/helpdesk/detailTicket.vue'),
        meta: {
            group : 'helpdesk',
            module : 'Helpdesk',
            permissions : 'Request',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Helpdesk'            
        },
        children: [
           
        ]
      },
      {
        path: 'support-ticket/id=:id',
        name: 'supportTicket',
        props : true,
        component: () => import(/* webpackChunkName: "supportTicket" */ '@/components/helpdesk/detailSupportTicket.vue'),
        meta: {
            group : 'helpdesk',
            module : 'Helpdesk',
            permissions : 'Support',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Helpdesk'            
        },
        children: [
           
        ]
      },
      {
        path: ':tabs',
        name: 'helpdesk',
        props : true,
        component: () => import(/* webpackChunkName: "helpdesk" */ '../views/helpdeskView.vue'),
        meta: {
            group : 'helpdesk',
            permissions : 'Read',
            module : 'Helpdesk',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Helpdesk',
        },
        children: [
           
        ]
      }
    ]
  },
  {
    path: '/system-administrator',
    name: 'administrator',
    component: () => import(/* webpackChunkName: "administrator" */ '../views/SistemAdminView.vue'),
    meta: {
        group : 'administrator',
        permissions : 'Administrator',
        module : 'Administrator',
        layout: AdminLayout,
        requiresAuth: true,
        requiresPermission: true,
        pagename: 'Administrator',
        icon: 'security'
    } 
  },
  {
    path: '/letter',
    children: [
      {
        path: 'tabs=:tabs',
        props : true,
        name: 'letter',
        component: () => import(/* webpackChunkName: "letter" */ '@/components/letters/letter-component.vue'),
        meta: {
            group : 'letter',
            permissions : 'Input',
            module : 'Letter',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      },
      {
        path: 'input-letter/:id?',
        props: route => ({
          id: route.params.id || null  // jika id tidak ada, gunakan null
        }),
        name: 'input-letter',
        component: () => import(/* webpackChunkName: "input-letter" */ '@/components/letters/inputLetter.vue'),
        meta: {
            group : 'letter',
            permissions : 'Input',
            module : 'Letter',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      },
      {
        path: 'detail/letter_id=:letter_id',
        props: true,
        name: 'detail-letter',
        component: () => import(/* webpackChunkName: "detail-letter" */ '@/components/letters/detailLetter.vue'),
        meta: {
            group : 'letter',
            permissions : 'Input',
            module : 'Letter',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      },
      {
        path: '/form-disposisi/inputer=:inputer_id/letter-id=:letter_id',
        props : true,
        name: 'form-disposisi',
        component: () => import(/* webpackChunkName: "form-disposisi" */ '@/components/letters/letterDisposisiForm.vue'),
        meta: {
            group : 'letter',
            permissions : 'Input',
            module : 'Letter',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      }
    ]
  },
  {
    path: '/letter-approval',
    children: [
      {
        path: 'tabs=:tabs',
        props : true,
        name: 'letter-approval',
        component: () => import(/* webpackChunkName: "letter-approval" */ '@/components/letters/letter-approval-component.vue'),
        meta: {
            group : 'letter-approval',
            permissions : 'Approval',
            module : 'Letter',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      },
      {
        path: '/form-approval/approval-id=:approval_id/letter-id=:letter_id',
        props : true,
        name: 'form-letter-approval',
        component: () => import(/* webpackChunkName: "form-letter-authorization" */ '@/components/letters/formApproval.vue'),
        meta: {
            group : 'letter-approval',
            permissions : 'Approval',
            module : 'Letter',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: true,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      },
      {
        path: 'detail-letter-approval/letter_id=:letter_id',
        props : true,
        name: 'detail-letter-approval',
        component: () => import(/* webpackChunkName: "detail-disposition" */ '@/components/letters/detailLetter.vue'),
        meta: {
            group : 'detail-letter-approval',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Letter',
            icon: 'Mail',
        } 
      }
    ]
  },
  {
    path: '/disposition',
    children: [
      {
        path: '',
        props : true,
        name: 'disposition',
        component: () => import(/* webpackChunkName: "disposition" */ '@/components/letters/dispositionView.vue'),
        meta: {
            group : 'disposition',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Disposition',
            icon: 'Mail',
        } 
      },
      {
        path: 'form-disposition/letter_id=:letter_id',
        props : true,
        name: 'form-disposition',
        component: () => import(/* webpackChunkName: "form-disposition" */ '@/components/letters/dispositionForm.vue'),
        meta: {
            group : 'disposition',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Disposition',
            icon: 'Mail',
        } 
      },
      {
        path: 'detail-disposition/letter_id=:letter_id',
        props : true,
        name: 'detail-disposition',
        component: () => import(/* webpackChunkName: "detail-disposition" */ '@/components/letters/detailLetter.vue'),
        meta: {
            group : 'disposition',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Disposition',
            icon: 'Mail',
        } 
      },
      
    ]
  },
  {
    path: '/delegation',
    children: [
      {
        path: 'tabs=:tabs',
        props : true,
        name: 'delegation',
        component: () => import(/* webpackChunkName: "delegation" */ '@/views/delegationView.vue'),
        meta: {
            group : 'delegation',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Delegation',
            icon: 'Mail',
        } 
      },
      {
        path: 'detail/id=:id',
        props : true,
        name: 'delegatedDetail',
        component: () => import(/* webpackChunkName: "delegatedDetail" */ '@/components/delegation/delegated_detail.vue'),
        meta: {
            group : 'delegation',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Delegation',
            icon: 'Mail',
        } 
      },
      {
        path: 'need_approval/id=:id/employee_id=:employee_id',
        props : true,
        name: 'delegatedNeedApproval',
        component: () => import(/* webpackChunkName: "delegatedNeedApproval" */ '@/components/delegation/delegated_need_approve.vue'),
        meta: {
            group : 'delegation',
            layout: AdminLayout,
            requiresAuth: true,
            requiresPermission: false,
            pagename: 'Delegation',
            icon: 'Mail',
        } 
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


function loggedIn() {
  if(ls.get('erp-session')){
    const token_expires = ls.get('erp-session').data.token_expires
    const expired = moment(token_expires)
    const today = moment()
    
    if(today < expired){
      return localStorage.getItem('erp-session')
    }else{
      localStorage.clear();
      return false
    }
  }else{
    localStorage.clear();
    return false
  }
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
     if (!loggedIn()) {
       next({ name: 'login' });
     } else {
       if (to.matched.some((record) => record.meta.requiresPermission)) {
         const ls = new SecureLS({ isCompression: false });
         const privilege = JSON.parse(JSON.parse(ls.get('erp-session').data.role).privilege);
         let hasPermission = false;
         for (let i = 0; i < privilege.length; i++) {
           if (to.meta.module === privilege[i].module) {             
             const permissions = privilege[i].permission;
             const requiredPermission = to.meta.dynamicPermission && to.meta.dynamicPermission(to) ? to.meta.dynamicPermission(to) : to.meta.permissions;
             if (permissions[requiredPermission] === true) {
               hasPermission = true;
               break;
             }
           }
         }
         if (hasPermission) {
           next();
         } else {
           next({ name: 'forbidden' });
         }
       } else {
         next();
       }
     }
  } else if (loggedIn() && to.name === 'login') {
     next({ name: 'home' });
  }  else if (!to.matched.length) {
     next({ name: 'not-found' });
  }  else {
     next();
  }
 });

export default router
