<template>
    <div>
        <div class="w-full flex flex-col gap-6 text-gray-800">
            <div class="w-full grid grid-cols-[50px_1fr] justify-start items-center gap-4" v-for="(c,i) in custome" :key="i">
                <div class="flex justify-center w-[45px] h-[45px] items-center border border-gray-300 rounded-full overflow-hidden">
                    <mdicon name="checkbox-marked-outline" class="text-gray-600" size="23"></mdicon>
                </div>
                <div class="text-gray-800 text-[14px] tracking-wider">{{c.custome_field}}:<span class="ml-2 font-light ">{{c.value}}</span></div>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmaErpCustome',
    props: ['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
    computed:{
       custome(){
            return this.data.custome
       }
    }
};
</script>

<style lang="scss" scoped>

</style>