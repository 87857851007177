<template>
    <div>
        <sevenDayNotif :data="data" @close="$emit('close')"></sevenDayNotif>
    </div>
</template>

<script>
import sevenDayNotif from './component/sevenDayNotif.vue';
export default {
    name: 'PadmaErpAssignNotification',
    props : ['data'],
    data() {
        return {
            
        };
    },

    mounted() {
        console.log(this.data);
    },

    methods: {
        
    },

    computed : {
        mentionData(){
            let employeeId = this.loggedInUser.employee_id
            return this.data.filter(item => {
                return item.mentions.some(mention => mention.id === employeeId);
            });
        }
    },

    components : {
        sevenDayNotif
    }
};
</script>

<style lang="scss" scoped>

</style>