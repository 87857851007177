<template>
  <div>
    <Transition duration="550" name="nested" @after-enter="addClickOutsideListener" @after-leave="removeClickOutsideListener">
     <div v-if="isOpen" >
       <div class="inner fixed overflow-hidden top-[50px] right-0 bg-white h-[calc(100vh-50px)] shadow-xl drop-shadow-lg max-w-2xl rounded-lg ">
         <div class="p-4">
           <div class="h-5">
             <v-btn icon="close" size="small" @click="closeSidebar"></v-btn>
           </div>
           <div class="py-4">
             <slot></slot>
           </div>
         </div>
       </div>
     </div>
   </transition>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeSidebar() {
      this.$emit('update:modelValue', false);
    },

    addClickOutsideListener() {
      // Tambahkan event listener ketika komponen muncul
      document.addEventListener('click', this.closeOnOutsideClick);
    },
    removeClickOutsideListener() {
      // Hapus event listener ketika komponen hilang
      document.removeEventListener('click', this.closeOnOutsideClick);
    },
    closeOnOutsideClick(event) {
      // Cek apakah klik dilakukan di luar .inner
      const innerElement = this.$el.querySelector('.inner');
      if (innerElement && !innerElement.contains(event.target)) {
        this.closeSidebar();
      }
    },

  },
  computed: {
    isOpen: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style scoped>
  
/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner { 
  transition: all 0.3s ease;
}
/* delay enter of nested element */

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(1000px);
  opacity: 0.01;
}
</style>

