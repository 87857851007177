<template>
    <div class="w-full px-4 pb-10">
        <div>
            <h2 class="text-3xl font-semibold mb-2">Wellcome {{ this.loggedInUser.employee.employee_name }} !</h2>
            <div class="text-gray-700">Have a productive and enjoyable day at work.</div>
        </div>       
        <div class="w-full flex mt-10 justify-center items-center">
          <img :src="dashboard" alt="">
        </div>
    </div>
  </template>
  
  <script>
  import dashboard from '@/assets/dashboard.png'
  export default {
    data() {
      return {
        dashboard : dashboard
      };
    },
    computed : {
    },
    mounted (){
    
    }
  };
  </script>
  
  <style scoped>
  /* Styling sesuai kebutuhan Anda */
  </style>
  