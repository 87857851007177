<template>
  <div class="home">
    <dashboard></dashboard>
 </div>
</template>

<script>
import dashboard from '@/components/dashboard/dashboard-component.vue'
export default {
  name: 'HomeView',
  components:{
    
  },
  mounted(){
    
  },
  components : {
    dashboard
  }
  
}
</script>
