<template>
  <div>
    <div class="relative w-full">
      <label class="block text-gray-700 text-sm font-medium mb-2" v-if="label">
        {{ label }} <span class="text-danger-700 text-[14px]" v-if="required">*</span>
      </label>
      <select
        @focus="focused = true"
        @blur="focused = false"
        v-model="selectedValue"
        :required="required"
        :disabled="disabled"
        @change="handleChange"
        class="border text-[14px] border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-normal focus:outline-none focus:border-primary-500"
        :class="transparent ? 'bg-transparent' : disabled ? 'bg-gray-50' : ''"
      >
        <option value="" :disabled="disabledOnSelect" :selected="disabledOnSelect">{{ placeholder }}</option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          class="text-[13px] text-gray-600 hover:bg-gray-200 focus:bg-gray-200"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    required: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: ''
    },
    label: {
      type: [String, Boolean],
      default: false
    },
    disabledOnSelect: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      focused: false,
      selectedValue: this.modelValue
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
    },
    selectedValue(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
  methods: {
    handleChange(event) {
      this.$emit('change', this.selectedValue);
    }
  }
};
</script>

<style scoped>
option {
  @apply px-2 py-1;
}
option:hover,
option:focus {
  @apply bg-gray-200 outline-none;
}
</style>
