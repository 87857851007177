<template>
  <div class="relative inline-block">
    <span class="tooltip-trigger" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
      <slot name="trigger"></slot>
    </span>
    <transition name="fade">
      <div v-if="showTooltip" class="tooltip bg-white text-gray-800 rounded shadow-lg absolute">
        <div class="tooltip-content p-2">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTooltip: false,
    };
  },
};
</script>

<style>
.tooltip-trigger {
  @apply cursor-pointer;
}

.tooltip {
  @apply top-full transform translate-y-2 -translate-x-[50%] bg-white;
  color: #333; /* Mengubah warna teks menjadi lebih gelap */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Menambahkan bayangan pada tooltip */
  z-index: 9999; /* Atur nilai z-index yang tinggi */
}

.tooltip-content {
  @apply p-2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
