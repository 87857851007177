<template>
    <div :class="[colClass, offsetClass]">
      <slot></slot>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue'
  
  export default {
    props: {
      span: {
        type: String,
        default: '1'
      },
      offset: {
        type: String,
        default: ''
      }
    },
    setup(props) {
      const colClass = computed(() => {
        return `col-span-${props.span}`
      })
  
      const offsetClass = computed(() => {
        return props.offset !== '' ? `col-start-${props.offset}` : ''
      })
  
      return {
        colClass,
        offsetClass
      }
    }
  }
  </script>
  
  <style scoped>
  .col-span-1 {
    grid-column: span 1 / span 1;
  }
  
  .col-span-2 {
    grid-column: span 2 / span 2;
  }
  
  .col-span-3 {
    grid-column: span 3 / span 3;
  }
  
  .col-span-4 {
    grid-column: span 4 / span 4;
  }
  
  .col-span-5 {
    grid-column: span 5 / span 5;
  }
  
  .col-start-1 {
    grid-column-start: 1;
  }
  
  .col-start-2 {
    grid-column-start: 2;
  }
  
  .col-start-3 {
    grid-column-start: 3;
  }
  
  .col-start-4 {
    grid-column-start: 4;
  }
  
  .col-start-5 {
    grid-column-start: 5;
  }
  </style>
  