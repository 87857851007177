<template>
  <div :class="[computedClasses, gridClasses]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PadmaErpVRow',
  props : {
      cols: {
        Type : [String, Number],
        default : '6'
      },
      gap: {
        Type : [String, Number],
        default : '0'
      },
      justify: {
        Type : String,
        default : 'center'
      },
      align: {
        Type : String,
        default : 'center'
      }
  },
  data() {
    return {  
      
    };
  },

  mounted() {
    console.log(this.cols)
  },

  methods: {
    
  },
  computed : {
    computedClasses(){
      return [
        `grid w-full`,
        `justify-${this.justify}`,
        `items-${this.align}`,
        `gap-${this.gap}`
      ]
    },

    gridClasses(){
      return [
        `grid-cols-${this.cols}`,
      ]
    },

    watch:{
      gridClasses:function(newValue){
        return newValue
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>