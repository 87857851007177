<template>
    <div class="flex justify-start items-center gap-[5px]">
        <div :class="[typeClass, 'rounded-full']" :style="circleStyle"></div>
        <div :class="[textClass, 'text-gray-700']">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmaErpVStatus',
    props: {
        type: {
            type: String,
            default: 'default'
        },
        size: {
            type: String,
            default: 'normal'
        }
    },
    data() {
        return {};
    },
    computed: {
        typeClass() {
            return this.type === 'primary' ? 'bg-primary-700' : this.type === 'warning' ? 'bg-warning-300' : this.type === 'danger' ? 'bg-danger-600' : 'bg-gray-600';
        },
        circleStyle() {
            const sizes = {
                xs: '6px',
                sm: '8px',
                normal: '10px',
                lg: '12px'
            };
            const size = sizes[this.size] || sizes.normal;
            return {
                width: size,
                height: size
            };
        },
        textClass() {
            return {
                'text-xs': this.size === 'xs',
                'text-sm': this.size === 'sm',
                'text-base': this.size === 'normal',
                'text-lg': this.size === 'lg'
            };
        }
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="scss" scoped>
</style>
