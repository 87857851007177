<template>
    <div class="px-4 py-6 h-[90vh] overflow-y-auto">
        <div class="inline-block relative mb-4">
            <div class="cursor-pointer hover:bg-gray-300 px-[10px] py-[10px] rounded  duration-300 ease" @click="dropMenu = !dropMenu">
                <div class="flex justify-start items-center gap-1">
                    <div class="text-gray-700 text-sm font-bold">Show</div>
                    <div class="text-gray-700 text-sm">{{ selectedStatus }}</div>
                    <mdicon name="chevron-down" size="20" class="text-gray-700"></mdicon>
                </div>
            </div>
            <Transition name="fade">
                <div class="absolute left-8 z-10 bg-white shadow-lg rounded-lg p-2" v-if="dropMenu">
                    <div v-for="(st,index) in status_list" :key="index" class="whitespace-nowrap tracking-wide px-[8px] py-[4px] rounded cursor-pointer hover:bg-primary-100 duration-300 ease text-[14px]" @click="updatePage(st)">{{ st }}</div>
                </div>
            </Transition>
        </div>
        <div v-if="showItem.length == 0" class="flex flex-col justify-start gap-4 items-center">
            <div class="w-[45%]">
                <img :src="nodataImage" alt="No Data Image" class="w-full h-full">
            </div>
            <div class="text-3xl font-bold text-gray-800">
                {{ textBold }}
            </div>
            <div>
                {{ textSmall }}
            </div>
        </div>
        <div v-if="showItem.length > 0">
            <div v-for="(message,index) in showItem" :key="index" class="mb-4">
                <messageItem :message="message" :messageIndex="index" type="inbox" @close="updateData" @update="getMessage"></messageItem>
            </div>
        </div>
    </div>
</template>

<script>
import noData from '@/assets/no-data.svg'
import messageItem from "./components/message-item.vue"

export default {
    name: 'PadmaErpInbox',

    data() {
        return {
            nodataImage : noData,
            dropMenu : false,
            selectedStatus : 'Unread updates',
            messages : [],
            status_list :  ['Unread updates','Read & Unread updates']
        };
    },

    mounted() {
        this.getMessage();
    },

    methods: {
        updatePage(val){
            this.selectedStatus = val;
            this.dropMenu = false;
        },
        updateData(val){
            if(val){
                this.getMessage();
            }
        },
        getMessage(){
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json'
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/message/${this.loggedInUser.username}/assign`,headers).then((response)=>{
                this.messages = response.data;
            }).catch((error)=>{
            })
        },
    },
    components : {
        messageItem
    },

    computed : {
        showItem(){
            if(this.selectedStatus === 'Read & Unread updates'){
                return this.messages
            } else {
                return this.messages.filter(message => {
                    // Memeriksa apakah message.receivers memiliki objek dengan username 'nurtaya' dan read 0 atau '0'
                    return message.receivers.some(receiver => {
                        return receiver.username === this.loggedInUser.username && receiver.read === 0;
                    });
                });
            }
        },

        textBold (){
            if(this.selectedStatus == 'Read & Unread updates'){
                return 'No read & unread updates'
            } else{
                return 'No unread updates'
            }
        },

        textSmall(){
            if(this.selectedStatus == 'Read & Unread updates'){
                return "To revisit updates you've already read, change the filter at the top left corner of your feed"
            } else {
                return "To see updates you haven't read, change the filter in the top left corner of your feed."
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}


::-webkit-scrollbar-track {
    @apply bg-gray-500 bg-opacity-30;
// background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
 @apply bg-gray-500 bg-opacity-50;
// background: rgb(185, 185, 185); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500 bg-opacity-100;
// background: rgb(182, 182, 182); 
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translateY(-50px);
}
</style>